import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { Container, Label, Button, Input, Popover, PopoverHeader, PopoverBody, Row, Col } from 'reactstrap';
import Countdown from 'react-countdown';
import config from '../config/config';
import moment from 'moment';
import PropTypes from 'prop-types';

class ExternalAssessment extends Component {

    static propTypes = {
        competences: PropTypes.array.isRequired,
        rating: PropTypes.array.isRequired,
        handleCheckbox: PropTypes.func.isRequired,
        handleObservation: PropTypes.func.isRequired,
        handleCheckOut: PropTypes.func.isRequired,
        handleSave: PropTypes.func.isRequired,
        handleRefresh: PropTypes.func.isRequired,
        editable: PropTypes.bool,
        checkOutPossible: PropTypes.bool,
        checkOutDateTime: PropTypes.string.isRequired,
        checkOutUserName: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: -1
        }
    }

    handleCheckbox = (id, value) => {
        this.props.handleCheckbox(id, value);
    }

    handleObservation = (id, e) => {
        this.props.handleObservation(id, e)
    }

    togglePopOver(index) {
        this.setState({
            popoverOpen: this.state.popoverOpen === index ? - 1 : index
        });
    }

    render() {
        let datetime, datetimeCheckOut = null;
        if (this.props.modified) {
            datetime = moment(this.props.modified.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.modified.replace(" ", "T"), config.dateFormat.format, true) : null;
        }
        if (this.props.checkOutDateTime) {
            datetimeCheckOut = moment(this.props.checkOutDateTime.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(this.props.checkOutDateTime.replace(" ", "T"), config.dateFormat.format, true) : null;
        }
        let checkInTime = new Date(datetimeCheckOut + 1200000);
        let now = new Date();
        let editable = this.props.editable && checkInTime > now;
        return (
            <Container fluid className="m-0 p- my-2 externalassessment">
                <Container className='d-block sticky2 bg-white m-0 p-0' fluid>
                    <Row className="pt-0 pe-0 pb-4 ps-0">
                        <Col xs="12" sm="12" md="12" lg="6" xl="3">
                            <Button type="submit"
                                color="primary"
                                disabled={!this.props.checkOutPossible}
                                onClick={this.props.handleRefresh}
                                className="me-2"
                            >
                                <img className="th-refresh" src={require('../assets/images/Reset.svg').default} alt="" />
                            </Button>
                            <Button type="submit"
                                color="primary"
                                disabled={!this.props.checkOutPossible}
                                onClick={this.props.handleCheckOut}
                            >
                                {editable ? <FormattedMessage id="button.extend_checkout" /> : <FormattedMessage id="button.checkout" />}
                            </Button>
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="6" xl="9" className="mt-md-2 mt-xs-2 mt-sm-2">
                            {editable ?
                                <span style={{ fontWeight: 'bold' }}>
                                    <FormattedMessage id="externalassessment.competences.automatic.save" />
                                    <Countdown date={checkInTime} daysInHours={true} onComplete={this.props.handleSave} />
                                </span> : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12">
                            <Container fluid className={"small ps-1 pe-1 pb-1 m-0 justify-content-start d-flex"}>
                                {/* Modified */
                                    this.props.modified &&
                                    <span><FormattedMessage id="externalassessment.label.modified" />&nbsp;<FormattedDate value={datetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={datetime} hour={config.dateFormat.hour} minute={config.dateFormat.minute} /></span>
                                }
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" sm="12">
                            <Container fluid className={"small ps-1 pe-1 pb-1 m-0 justify-content-start d-flex"}>
                                {/* Check out */
                                    this.props.checkOutDateTime !== '' &&
                                    <span><FormattedMessage id="externalassessment.label.checkout" />&nbsp;{this.props.checkOutUserName}&nbsp;<FormattedMessage id="externalassessment.label.since" />&nbsp;<FormattedDate value={datetimeCheckOut} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={datetimeCheckOut} hour={config.dateFormat.hour} minute={config.dateFormat.minute} /></span>
                                }
                            </Container>
                        </Col>
                    </Row>
                    {/* Table Head */}
                    <Container fluid className="m-0 px-3">
                        <Row>
                            <Col xs="4" className="text-uppercase border fw-bold d-flex flex-column justify-content-end py-2 text-ellipse">
                                <FormattedMessage id="externalassessment.label.competence" />
                            </Col>
                            <Col xs="4" className="text-uppercase border border-start-0 fw-bold d-flex flex-column justify-content-end py-2 text-ellipse">
                                <FormattedMessage id="externalassessment.label.observations" />
                            </Col>
                            <Col xs="1" className="th-smiley-container small fw-bold border border-start-0 py-2 text-ellipse">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_01.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="externalassessment.label.rating-1" />
                            </Col>
                            <Col xs="1" className="th-smiley-container small fw-bold border border-start-0 py-2 text-ellipse">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_02.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="externalassessment.label.rating-2" />
                            </Col>
                            <Col xs="1" className="th-smiley-container small fw-bold border border-start-0 py-2 text-ellipse">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_03.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="externalassessment.label.rating-3" />
                            </Col>
                            <Col xs="1" className="th-smiley-container small fw-bold border border-start-0 py-2 text-ellipse">
                                <img className="th-smiley mb-2" src={require('../assets/images/Smiley_04.svg').default} alt="" />
                                <br />
                                <FormattedMessage id="externalassessment.label.rating-4" />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {/* Table */}
                <Container fluid className='mb-4 px-3' >
                    {
                        this.props.competences.map((trData, trIndex) => {

                            let competencesObservations = this.props.rating.filter((el) => (el.competences_id === trData.id)).length > 0 ? this.props.rating.filter((el) => (el.competences_id === trData.id))[0].observations : null;

                            return <Row className="row-striped" key={trIndex} >
                                <Col xs="4" className="border py-2">
                                    <p className="text-ellipse"><FormattedMessage id={"externalassessment." + trData.description} /></p>
                                    <p className="text-uppercase text-italic mb-0 pb-0 text-ellipse "><em><FormattedMessage id={"externalassessment." + trData.name} /></em></p>
                                </Col>
                                <Col xs="4" className="p-2 competences-td border border-start-0 py-2 text-end">
                                    {/*Popover*/}
                                    <Label className="small" for={"Popover" + trIndex}>
                                        <FormattedMessage id="externalassessment.label.observationsnote" />
                                    </Label>
                                    <Button className="ms-1 info" color="link" name={"Popover" + trIndex} id={"Popover" + trIndex} onClick={this.togglePopOver.bind(this, trIndex)}></Button>

                                    <Popover className="externalassessment-popover" /* toggle={this.togglePopOver.bind(this, -1)} */ placement="right-start" aria-live="polite" aria-atomic="true" aria-hidden={!(this.state.popoverOpen === trIndex)} isOpen={(this.state.popoverOpen === trIndex)} target={"Popover" + trIndex} >
                                        <PopoverHeader><FormattedMessage id={"externalassessment.label.observationsnote"} /></PopoverHeader>
                                        <PopoverBody style={{ whiteSpace: "pre-wrap" }}>
                                            {this.props.observations && this.props.observations.text && this.props.observations.text.length > 0 ? this.props.observations.text : <FormattedMessage id={"externalassessment.placeholder.noobservationsnote"} />}
                                        </PopoverBody>
                                    </Popover>

                                    {/*Textarea*/}
                                    <Input type="textarea" disabled={!editable} name={trData.id} id={"observation" + trIndex} style={{ minHeight: "10rem" }} placeholder={this.props.intl.formatMessage({ id: "externalassessment.textarea.placeholder" })} value={competencesObservations ? competencesObservations : ""} onChange={this.handleObservation.bind(this, trData.id)} />
                                </Col>
                                <Col xs="1" className="p-0 competences-td border border-start-0 py-2">
                                    <Label className="competences" for={"1_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /> <FormattedMessage id="externalassessment.label.rating-1" /></div>}
                                        <input type="checkbox" disabled={!(editable && competencesObservations)} checked={this.props.rating.filter((el) => (el.competences_id === trData.id && (el.rating === 1 || el.rating2 === 1))).length > 0 && competencesObservations} name={trData.id} id={"1_" + trData.id} value="1" onChange={this.handleCheckbox.bind(this, trData.id, 1)} />
                                        <span className="checkmark"></span>
                                    </Label>
                                </Col>
                                <Col xs="1" className="p-0 competences-td border border-start-0 py-2">
                                    <Label className="competences" for={"2_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /> <FormattedMessage id="externalassessment.label.rating-2" /></div>}
                                        <input type="checkbox" disabled={!(editable && competencesObservations)} checked={this.props.rating.filter((el) => (el.competences_id === trData.id && (el.rating === 2 || el.rating2 === 2))).length > 0 && competencesObservations} name={trData.id} id={"2_" + trData.id} value="2" onChange={this.handleCheckbox.bind(this, trData.id, 2)} />
                                        <span className="checkmark"></span>
                                    </Label>
                                </Col>
                                <Col xs="1" className="p-0 competences-td border border-start-0 py-2">
                                    <Label className="competences" for={"3_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /> <FormattedMessage id="externalassessment.label.rating-3" /></div>}
                                        <input type="checkbox" disabled={!(editable && competencesObservations)} checked={this.props.rating.filter((el) => (el.competences_id === trData.id && (el.rating === 3 || el.rating2 === 3))).length > 0 && competencesObservations} name={trData.id} id={"3_" + trData.id} value="3" onChange={this.handleCheckbox.bind(this, trData.id, 3)} />
                                        <span className="checkmark"></span>
                                    </Label>
                                </Col>
                                <Col xs="1" className="p-0 competences-td border border-start-0 py-2">
                                    <Label className="competences" for={"4_" + trData.id} >{<div className="d-none"><FormattedMessage id={trData.name} /><FormattedMessage id="externalassessment.label.rating-4" /></div>}
                                        <input type="checkbox" disabled={!(editable && competencesObservations)} checked={this.props.rating.filter((el) => (el.competences_id === trData.id && (el.rating === 4 || el.rating2 === 4))).length > 0 && competencesObservations} name={trData.id} id={"4_" + trData.id} value="4" onChange={this.handleCheckbox.bind(this, trData.id, 4)} />
                                        <span className="checkmark"></span>
                                    </Label>
                                </Col>
                            </Row>
                        })
                    }
                </Container>
            </Container >
        );
    }

}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ExternalAssessment));