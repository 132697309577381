import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Label, Row, Col } from 'reactstrap';
import UploadFile from './UploadFile';
import RolesCheckbox from './RolesCheckbox';
import { authorizationRoles } from '../config/authorization';

class DocumentModal extends React.Component {
    static propTypes = {
        open: PropTypes.bool,
        toggle: PropTypes.func,
        document: PropTypes.object,
        handleAddDocument: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            document: { id: -1, documents_structur_id: -1, name: '', filename: '', filename_unique: '', filesize: 0, sort: -1, version: '', roles: [] },
            importFileData: null,
            roles: props.roles.filter($r => $r.id !== authorizationRoles.student),
            procedure: [],
            procedures: [
                { id: 1, name: 'KomPo7' },
                { id: 2, name: 'KomPoG' },
            ]
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.document !== this.props.document) {
            this.setState({ importFileData: null, document: this.props.document });
        }

        if (prevProps.roles !== this.props.roles) {
            this.setState({ roles: this.props.roles.filter($r => $r.id !== authorizationRoles.student) });
        }
        if (prevProps.open !== this.props.open && this.props.open) {
            this.setState({ importFileData: null, document: this.props.document });
        }

    }

    handleName = (e) => {
        this.setState({ document: { ...this.state.document, name: e.target.value } });
    }
    handleVersion = (e) => {
        this.setState({ document: { ...this.state.document, version: e.target.value } });
    }

    handleSubmit = (e) => {
        this.props.handleAddEditDocument(this.state.document, this.state.importFileData);
    }

    handleOnUploadSuccess = async (response) => {
        this.setState({ openModalImport: true, importFileData: response });
    }

    handleOnUploadFailure = async (err) => {
        this.setState({ showImportUploadError: true, importUploadError: err, importFileData: null });
    }

    handleRoles = (roles) => {
        if (roles.some(r => r.id === authorizationRoles.school || r.id === authorizationRoles.teacher)) {
            this.setState({ document: { ...this.state.document, roles: roles } });
        } else {
            this.setState({ document: { ...this.state.document, roles: roles, procedure: [] } });
        }
    }

    handleProcedures(procedure) {
        this.setState({ document: { ...this.state.document, procedure: procedure } });
    }

    render() {
        return (
            <Container>
                <Modal isOpen={this.props.open} toggle={this.props.toggle} size="lg" backdrop={'static'} className="modal-height-100 modal-width-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.props.toggle} >
                    <ModalHeader>
                        {this.state.document.id === -1 ?
                            <FormattedMessage id="documents.modal.title.add" />
                            :
                            <FormattedMessage id="documents.modal.title.edit" />
                        }
                    </ModalHeader>

                    <ModalBody>
                        {
                            <Container className="m-0 px-3">
                                <Row className="border-bottom border-top">
                                    <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                        <Label className="fw-bold d-flex align-items-center h-100" for="name"><FormattedMessage id="documents.modal.name" /></Label>
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        <Input bsSize="sm" type="input" name="name" id="name" value={this.state.document.name} onChange={this.handleName}></Input>
                                    </Col>
                                </Row>
                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                        <Label className="fw-bold d-flex align-items-center h-100" for="version"><FormattedMessage id="documents.modal.version" /></Label>
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" md="9">
                                        <Input bsSize="sm" type="input" name="version" id="version" value={this.state.document.version} onChange={this.handleVersion}></Input>
                                    </Col>
                                </Row>

                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                        <FormattedMessage id="documents.modal.roles" />
                                    </Col>
                                    <Col className="mb-2 mt-2" xs="12" sm="12" md="9">
                                        <RolesCheckbox checkAllowedCombination={false} withEmpty className="m-0 p-0" roles={this.state.roles} handleRoles={this.handleRoles.bind(this)} selectedRoles={this.state.document ? this.state.document.roles : []} />
                                    </Col>
                                </Row>

                                {/* PROCEDURES */}
                                {this.state.document.roles.some(r => r.id === authorizationRoles.school || r.id === authorizationRoles.teacher) &&
                                    <Row className="border-bottom">
                                        <Col className="mb-2 mt-2 fw-bold d-flex align-items-center mh-100" xs="12" md="3">
                                            <FormattedMessage id="procedureselect.header.default" />
                                        </Col>
                                        <Col className="mb-2 mt-2" xs="12" sm="12" md="9">
                                            <RolesCheckbox withEmpty className="m-0 p-0" roles={this.state.procedures} feedbacklabel="useradministrationmodal.feedback.empty" invalid={false} handleRoles={this.handleProcedures.bind(this)} selectedRoles={this.state.document && this.state.document.procedure ? this.state.document.procedure : []} checkAllowedCombination={false} showAsRadio={false} />
                                        </Col>
                                    </Row>
                                }

                                <Row className="border-bottom">
                                    <Col className="mb-2 mt-2 fw-bold" xs="12" md="3">
                                        <Label className="fw-bold d-flex align-items-center h-100" for="upload"><FormattedMessage id="documents.modal.upload" /></Label>
                                    </Col>
                                    <Col className="mb-2 mt-2 d-flex flex-column" xs="12" md="9">
                                        {this.state.importFileData ?
                                            <div>{this.state.importFileData.import_file_orig}</div> :
                                            <FormattedMessage id="documents.modal.upload.none" />}
                                        <UploadFile className="p-0" uploadUrl="/documents/import"
                                            onUploadSuccess={this.handleOnUploadSuccess.bind(this)}
                                            onUploadFailure={this.handleOnUploadFailure.bind(this)}
                                            allowedFileExts="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                                            buttonText={this.props.intl.formatMessage({ id: 'documents.modal.button.import' })} />

                                    </Col>
                                </Row>
                            </Container>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button type="submit" color="primary" onClick={this.props.toggle.bind(this)}><FormattedMessage id="button.cancel" /></Button>
                        <Button type="submit" color="primary"
                            disabled={this.state.document.name.length < 3 || (this.state.importFileData === null && this.state.document.filename === '') || this.state.document.roles.length === 0
                                || (this.state.document.roles.some(r => r.id === authorizationRoles.school || r.id === authorizationRoles.teacher) && this.state.document.procedure.length === 0)}
                            onClick={this.handleSubmit.bind(this)}>
                            <FormattedMessage id="button.save" />
                        </Button>
                    </ModalFooter>

                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        roles: state.userProfile.roles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentModal));