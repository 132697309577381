import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Table, Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input, Label } from 'reactstrap';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import Pages from '../components/Pages';
import SchoolSelect from '../components/SchoolSelect';
import Confirm from '../components/Confirm';
import SchoolyearsSelect from '../components/SchoolyearsSelect';
import ProcedureSelect from '../components/ProcedureSelect';
import StudentAdministrationModal from '../components/StudentAdministrationModal';
import ImportStudentsModal from '../components/ImportStudentsModal';
import UploadFile from '../components/UploadFile';
import PropTypes from 'prop-types';
import config from '../config/config';
import { schoolsActions, userProfileActions, filterActions } from '../actions';
import { generateResetPwdUsersPDF } from '../pdf';

class StudentAdministrationPage extends Component {

    static propTypes = {
        schoolId: PropTypes.number
        // redux dispatch
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            totalpages: 1,
            pages: 5,
            pagelimit: 10,
            loading: false,
            confirm: false,
            confirmReset: false,
            editData: null,
            deleteId: -1,
            resetId: -1,
            school: null,
            order: "",
            desc: false,
            search: "",
            th: [
                { text: "studentadministration.th.firstname", order: "firstname", width: "22%" },
                { text: "studentadministration.th.lastname", order: "lastname", width: "22%" },
                { text: "studentadministration.th.email", order: "email", width: "22%" },
                { text: "studentadministration.th.class", order: "class", width: "12%" },
                { text: "studentadministration.th.schoolyears", order: "schoolyears", width: "12%" },
                { text: "studentadministration.th.action", className: "table-border-left-action", width: "10%" },
            ],
            tb: [],
            tbshow: ["firstname", "lastname", "email", "class", "schoolyears"],
            openModalNewEdit: false,
            modalProcedure: null,
            openModalImport: false,
            showImportUploadError: false,
            importUploadError: null,
            alert: { visible: false, textId: "alert.save", color: "success" },
            searchTimer: null,
            importFileData: null,
            firstLoad: true,
            firstLoad2: true,
            confirmPwdResetStudentPdf: false,
            resetPwdStudentIdPdf: -1,
            showGlobalLoading: false
        }
    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
    }

    componentDidUpdate(prevProps) {
        if ((this.state.firstLoad || prevProps.school !== this.props.school) && this.props.school.procedures && this.props.school.procedures.length > 1) {
            this.setState({
                th: [
                    { text: "studentadministration.th.firstname", order: "firstname", width: "22%" },
                    { text: "studentadministration.th.lastname", order: "lastname", width: "22%" },
                    { text: "studentadministration.th.email", order: "email", width: "22%" },
                    { text: "studentadministration.th.class", order: "class", width: "8%" },
                    { text: "studentadministration.th.schoolyears", order: "schoolyears", width: "8%" },
                    { text: "studentadministration.th.procedure", order: "procedureString", width: "8%" },
                    { text: "studentadministration.th.action", className: "table-border-left-action", width: "10%" },
                ],
                tbshow: ["firstname", "lastname", "email", "class", "schoolyears", "procedureString"],
                firstLoad: false
            })
        }


        if (this.state.firstLoad2 || prevProps.schoolId !== this.props.schoolId || prevProps.schoolyearId !== this.props.schoolyearId) {
            this.getData();
            this.setState({ firstLoad2: false })
        }

        if (prevProps.schoolId !== this.props.schoolId || prevProps.procedureId !== this.props.procedureId) {
            this.getData();
        }

        if (prevProps.total !== this.props.total) {
            //Check if the page still exists
            if (this.state.page > Math.ceil(this.props.total / this.state.pagelimit)) {
                let newPage = Math.ceil(this.props.total / this.state.pagelimit);
                this.setState({ page: newPage <= 0 ? 1 : newPage });
            }
        }

        /* alert delete */
        if (prevProps.deleteUserState !== this.props.deleteUserState) {
            if (this.props.deleteUserState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.delete", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.deleteUserState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.delete-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* alert create and update */
        if (prevProps.createUserState !== this.props.createUserState || prevProps.updateUserState !== this.props.updateUserState) {
            if (this.props.createUserState === 1 || this.props.updateUserState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.createUserState === 2 || this.props.updateUserState === 2) {
                if (this.props.createError === 'email' || this.props.updateError === 'email') {
                    this.setState({ alert: { visible: true, textId: "studentadministration.alert.email", color: "danger" } });
                } else {
                    this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                }
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* reset pwd teacher pdf */
        if (prevProps.resetPwdUserPdfState !== this.props.resetPwdUserPdfState) {
            if (this.props.resetPwdUserPdfState === 1) {
                if (this.props.resetPwdUserPdfUserdata !== null) {

                    generateResetPwdUsersPDF(this.props, this.state, [this.props.resetPwdUserPdfUserdata]);

                    // hide the loader if the pdf was created
                    this.setState({ showGlobalLoading: false });
                } else {
                    this.setState({ alert: { visible: true, textId: "useradministration.alert.resetpwdteacherpdf.failure", color: "danger" }, showGlobalLoading: false });
                    setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                }

            } else if (this.props.resetPwdUserPdfState === 2) {
                this.setState({ alert: { visible: true, textId: "useradministration.alert.resetpwdteacherpdf.failure", color: "danger" }, showGlobalLoading: false });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

    }

    getData() {
        this.props.dispatchGetSchoolsStudents(this.props.auth.token, this.props.schoolId, this.state.order, this.state.desc, this.state.search, this.state.page, this.state.pagelimit, [{ schoolyearId: this.props.schoolyearId }, { procedureId: this.props.procedureId }]);
    }

    handleEdit = (id) => {
        this.handleOpenModalNewEdit(id)
    }


    handleDelete = (id) => {
        this.setState({ deleteId: id });
        this.toggleConfirm();
    }

    handleReset = (id) => {
        this.setState({ resetId: id });
        this.toggleConfirmReset();
    }

    toggleConfirmReset = (e) => {
        this.setState({
            confirmReset: !this.state.confirmReset
        });
    }

    reset = (e) => {
        if (this.state.resetId) {
            this.props.dispatchResetUser(this.props.auth.token, this.state.resetId);
        }
        this.toggleConfirmReset();
    }

    handleOpenModalNewEdit = (id, e) => {
        let index = this.props.tb.map((value) => { return value.id }).indexOf(id);
        if (id === -1 || index === -1) {
            this.setState({ editData: null })
            if (e) {
                this.setState({ modalProcedure: e });
            }
        } else {
            this.setState({ editData: this.props.tb[index] });
        }
        this.setState({
            openModalNewEdit: !this.state.openModalNewEdit
        });
    }

    handleSchool = (school) => {
        this.setState({ school: school });
    }

    handlePage = (page) => {
        this.setState({ page: page });
        this.props.dispatchGetSchoolsStudents(this.props.auth.token, this.props.schoolId, this.state.order, this.state.desc, this.state.search, page, this.state.pagelimit, [{ schoolyearId: this.props.schoolyearId }, { procedureId: this.props.procedureId }]);
    }

    search = (e) => {
        let search = e.target.value
        let page = 1
        this.setState({ search: search, page: page });

        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
        }
        //start search after 500ms
        this.searchTimer = setTimeout(() => {
            this.props.dispatchGetSchoolsStudents(this.props.auth.token, this.props.schoolId, this.state.order, this.state.desc, search, page, this.state.pagelimit, [{ schoolyearId: this.props.schoolyearId }, { procedureId: this.props.procedureId }]);
        }, 500);
    }

    order = (order, desc, e) => {
        this.setState({ order: order, desc: desc });
        this.props.dispatchGetSchoolsStudents(this.props.auth.token, this.props.schoolId, order, desc, this.state.search, this.state.page, this.state.pagelimit, [{ schoolyearId: this.props.schoolyearId }, { procedureId: this.props.procedureId }]);
    }

    handleOnUploadSuccess = (response, procedure) => {
        this.setState({ openModalImport: true, importFileData: response, modalProcedure: { id: procedure, name: procedure === 1 ? 'KomPo7' : 'KomPoG' } });
    }

    handleOnUploadFailure = (err) => {
        this.setState({ showImportUploadError: true, importUploadError: err });
    }

    handleOpenModalImport = (e) => {
        const openModalImportTmp = !this.state.openModalImport
        this.setState({
            openModalImport: openModalImportTmp
        });

        // refresh the student list if the import window was closed
        if (openModalImportTmp === false) {
            this.getData();
        }

    }

    handleToggleModalUploadError = (e) => {
        this.setState({
            showImportUploadError: !this.state.showImportUploadError
        });
    }

    handleCloseUploadErrorModal = (e) => {
        this.handleToggleModalUploadError();
    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    save(user) {
        if (!user.id) {
            this.props.dispatchCreateUser(this.props.auth.token, user);
            this.handleOpenModalNewEdit();
        } else {
            this.props.dispatchUpdateUser(this.props.auth.token, user);
            this.handleOpenModalNewEdit();
        }
    }

    delete = (e) => {
        if (this.state.deleteId) {
            this.props.dispatchDeleteUser(this.props.auth.token, this.state.deleteId);
        }
        this.toggleConfirm();
    }

    hideAlert() {
        this.setState({ alert: { visible: false, textId: "alert.save", color: "success" } })
    }

    /* CLICK RESET PWD FOR SINGLE TEACHER */
    handleConfirmPwdResetPdf = (studentId) => {
        this.setState({
            confirmPwdResetStudentPdf: !this.state.confirmPwdResetStudentPdf,
            resetPwdStudentIdPdf: studentId,
        });
    };

    toggleConfirmPwdResetStudentPdf = () => {
        this.setState({
            confirmPwdResetStudentPdf: !this.state.confirmPwdResetStudentPdf,
            resetPwdStudentIdPdf: -1
        });
    };

    resetPwdOfStudentPdf = () => {
        if (this.state.resetPwdStudentIdPdf > -1) {
            this.props.dispatchResetPwdUserPdf(this.props.auth.token, this.state.resetPwdStudentIdPdf);
            this.setState({ showGlobalLoading: true });
        }

        this.setState({ confirmPwdResetStudentPdf: false, resetPwdStudentIdPdf: -1 })
    };

    render() {
        return (
            <Container fluid role="main" className="content-margin">

                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                {/* Confirm delete */}
                <Confirm open={this.state.confirm} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.delete} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                {/* Confirm reset */}
                <Confirm open={this.state.confirmReset} toggle={this.toggleConfirmReset} no={this.toggleConfirmReset} yes={this.reset} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                {/* Confirm reset pwd pdf */}
                <Confirm open={this.state.confirmPwdResetStudentPdf} toggle={this.toggleConfirmPwdResetStudentPdf} no={this.toggleConfirmPwdResetStudentPdf} yes={this.resetPwdOfStudentPdf} headertext="useradministration.confirm.header.resetpwdteacherpdf" bodytext="useradministration.confirm.body.resetpwdteacherpdf.teacher" />

                <h1><FormattedMessage id="studentadministration.label.student" /></h1>

                {/* Search */}
                <Container fluid className="m-0 p-0 mb-3">
                    <Row>
                        <Col xs="12" md="6" lg="6" xl="4">
                            <Input type="text" id="search" bsSize="sm" name="search" placeholder={this.props.intl.formatMessage({ id: "studentadministration.search.placeholder" })} autoComplete="off" onKeyUp={this.search} />
                        </Col>
                    </Row>
                </Container>

                { /* New Student and student import */}
                <Row>
                    <Col xs="12" md="6" lg="6" xl="4" className="mb-3">
                        {(this.props.school.procedures && this.props.school.procedures.length > 1) &&
                            <div>
                                <Label className={"fw-bold"}><FormattedMessage id="studentadministration.button.new-student" /></Label>
                                <Row>
                                    {(this.props.auth.procedure === 1 || this.props.auth.procedure === 0) &&
                                        <Col xs="12" md="6" lg="6" xl="6" className="mb-3">
                                            <Button block color="primary" onClick={this.handleOpenModalNewEdit.bind(this, -1, { id: 1, name: 'studentadministration.kompo7' })} id="newStudent"><FormattedMessage id="studentadministration.kompo7" /></Button>
                                        </Col>
                                    }
                                    {(this.props.auth.procedure === 2 || this.props.auth.procedure === 0) &&
                                        <Col xs="12" md="6" lg="6" xl="6" className="mb-3">
                                            <Button block color="primary" onClick={this.handleOpenModalNewEdit.bind(this, -1, { id: 2, name: 'studentadministration.kompoG' })} id="newStudent"><FormattedMessage id="studentadministration.kompoG" /></Button>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        }
                        {(this.props.school.procedures && this.props.school.procedures.length === 1) &&
                            <div>
                                <Row>
                                    <Col xs="12" md="6" lg="6" xl="12" className="mb-3">
                                        <Button block color="primary" onClick={this.handleOpenModalNewEdit.bind(this, -1, { id: this.props.school.procedures[0].id, name: this.props.school.procedures[0].name })} id="newStudent"><FormattedMessage id="studentadministration.button.new-student" /></Button>
                                    </Col>
                                </Row>

                            </div>
                        }
                    </Col>
                    <Col xs="12" md="6" lg="6" xl="4" className="mb-3">
                        {(this.props.school.procedures && this.props.school.procedures.length > 1) &&
                            <div>
                                <Label className={"fw-bold"}><FormattedMessage id="studentadministration.button.importstudents" /></Label>
                                <Row>
                                    {(this.props.auth.procedure === 1 || this.props.auth.procedure === 0) &&
                                        <Col xs="12" md="6" lg="6" xl="6" className="mb-3">
                                            <UploadFile className="p-0" uploadUrl="/users/students/import/init" onUploadSuccess={this.handleOnUploadSuccess.bind(this)} onUploadFailure={this.handleOnUploadFailure.bind(this)} allowedFileExts="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" buttonText={this.props.intl.formatMessage({ id: 'studentadministration.kompo7' })} procedure={1} />
                                        </Col>
                                    }
                                    {(this.props.auth.procedure === 2 || this.props.auth.procedure === 0) &&
                                        <Col xs="12" md="6" lg="6" xl="6" className="mb-3">
                                            <UploadFile className="p-0" uploadUrl="/users/students/import/init" onUploadSuccess={this.handleOnUploadSuccess.bind(this)} onUploadFailure={this.handleOnUploadFailure.bind(this)} allowedFileExts="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" buttonText={this.props.intl.formatMessage({ id: 'studentadministration.kompoG' })} procedure={2} />
                                        </Col>
                                    }
                                </Row>
                            </div>
                        }
                        {(this.props.school.procedures && this.props.school.procedures.length === 1) &&
                            <div>
                                <Row>
                                    <Col xs="12" md="6" lg="6" xl="12" className="mb-3">
                                        <UploadFile className="p-0" uploadUrl="/users/students/import/init" onUploadSuccess={this.handleOnUploadSuccess.bind(this)} onUploadFailure={this.handleOnUploadFailure.bind(this)} allowedFileExts="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" buttonText={this.props.intl.formatMessage({ id: 'studentadministration.button.importstudents' })} procedure={this.props.school.procedures[0].id} />
                                    </Col>
                                </Row>

                            </div>
                        }
                    </Col>
                </Row>

                <Row className="mb-3">
                    { /* Select School */}
                    <Col className={this.props.schools && this.props.schools.length <= 1 && "d-none"} xs="12" md="4" lg="4" xl="4">
                        <SchoolSelect className="mb-3 p-0" label="studentadministration.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                    </Col>
                    { /* Select Schoolyear */}
                    <Col xs="12" md="4" lg="4" xl="4">
                        <SchoolyearsSelect className="m-0 p-0" label="studentadministration.label.schoolyears" name="schoolyears" withEmpty schoolyears={this.props.schoolyears} schoolyearsId={this.props.schoolyearId} handleSchoolyears={this.props.dispatchSchoolyear} />
                    </Col>
                    {/* Select Procedure */}
                    {(this.props.school.procedures && this.props.school.procedures.length > 1) &&
                        <Col xs="12" md="4" lg="4" xl="4">
                            <ProcedureSelect className="m-0 p-0" label="studentadministration.label.procedures" name="procedures" withEmpty procedures={this.props.school.procedures ? this.props.school.procedures : []} procedureId={this.props.procedureId} handleProcedures={this.props.dispatchProcedure} />
                        </Col>
                    }
                </Row>

                { /* Table */}
                <Container fluid className="m-0 p-0 border">
                    <Table striped bordered className="m-0 table-fixed p-0 w-100">
                        <TableHead data={this.state.th} order={this.order} />
                        <TableBody loading={this.props.loading} data={this.props.tb} show={this.state.tbshow} edit={this.handleEdit.bind(this)} delete={this.handleDelete.bind(this)} reset={this.handleReset.bind(this)}
                            resetPwdUserPdf={this.handleConfirmPwdResetPdf} />
                    </Table>
                </Container>

                { /* Pages */}
                <Container fluid className="d-flex justify-content-end mt-3 p-0">
                    <Pages pages={parseInt(this.state.pages, 10)} totalpages={parseInt(this.props.totalPages, 10)} page={parseInt(this.state.page, 10)} changePage={this.handlePage} />
                </Container>

                { /* MODAL for new and edit */}
                <StudentAdministrationModal school={this.props.school} open={this.state.openModalNewEdit} toggle={this.handleOpenModalNewEdit} student={this.state.editData} save={this.save.bind(this)} procedure={this.state.modalProcedure} />

                { /* MODAL for new and edit */}
                <ImportStudentsModal school={this.props.school} open={this.state.openModalImport} toggle={this.handleOpenModalImport} importFileData={this.state.importFileData} refreshData={this.getData.bind(this)} procedure={this.state.modalProcedure} />

                { /* MODAL for new upload error */}
                <Modal isOpen={this.state.showImportUploadError} toggle={this.handleOpenModalUploadError} size="lg" backdrop={'static'} className="modal-height-100 modal-width-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.handleOpenModalUploadError} >
                    <ModalHeader><FormattedMessage id="uploaderrormodal.title" /></ModalHeader>

                    <ModalBody>
                        {
                            this.state.importUploadError &&
                            <Row><Col>{this.state.importUploadError}</Col></Row>
                        }
                        {    /*this.state.importUploadError && typeof this.state.importUploadError === 'object' && this.state.importUploadError.errors.map((el) => {
                                return <Row><Col>{el}</Col></Row>
                            })*/
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCloseUploadErrorModal.bind(this)}><FormattedMessage id="button.close" /></Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        school: state.filter.school && state.filter.school ? state.filter.school : {},
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        tb: state.schools.schoolsStudents ? state.schools.schoolsStudents : [],
        loading: state.schools.loading,
        totalPages: state.schools.total_pages,
        total: state.schools.total,
        createUserState: state.userProfile.createUserState,
        updateUserState: state.userProfile.updateUserState,
        createError: state.userProfile.createError,
        updateError: state.userProfile.updateError,
        deleteUserState: state.userProfile.deleteUserState,
        schoolyears: state.schools.schoolyears,
        schoolyearId: state.filter.schoolyear && state.filter.schoolyear.id ? state.filter.schoolyear.id : -1,
        procedures: state.filter.school ? state.filter.school.procedures : [],
        procedureId: state.filter.procedure /*&& state.filter.procedure.id*/ ? state.filter.procedure.id : -1,
        resetPwdUserPdfState: state.userProfile.resetPwdUserPdfState,
        resetPwdUserPdfUserdata: state.userProfile.resetPwdUserPdfUserdata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, true));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetSchoolsStudents: (token, schoolId, order, desc, search, page, pagelimit, filter) => {
            dispatch(schoolsActions.getSchoolsStudents(token, schoolId, order, desc, search, page, pagelimit, filter));
        },
        dispatchCreateUser: (token, user) => {
            dispatch(userProfileActions.createUser(token, user));
        },
        dispatchUpdateUser: (token, user) => {
            dispatch(userProfileActions.updateUser(token, user));
        },
        dispatchGetSchoolyears: (token) => {
            dispatch(schoolsActions.getSchoolyears(token, "start", false));
        },
        dispatchSchoolyear: (schoolyear) => {
            dispatch(filterActions.schoolyear(schoolyear));
        },
        dispatchDeleteUser: (token, userId) => {
            dispatch(userProfileActions.deleteUser(token, userId));
        },
        dispatchResetUser: (token, userId) => {
            dispatch(userProfileActions.resetUser(token, userId));
        },
        dispatchProcedure: (procedure) => {
            dispatch(filterActions.procedure(procedure));
        },
        dispatchResetPwdUserPdf: (token, teacherId) => {
            dispatch(userProfileActions.resetPwdUserPdf(token, teacherId));
        },
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(StudentAdministrationPage));