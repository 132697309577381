import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Button, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import ExecutionSelect from '../components/ExecutionSelect';
import GeneratePDF from '../components/GeneratePDF';
import translationsVars from '../translations/translationsVars';
import { motivationActions, kompoGResultActions } from '../actions';
import { generateMotivationExercisePDF } from '../pdf/MotivationExercisePDF';
import Loading from '../components/Loading';
import rightButton from '../assets/images/keyboard_arrow_right-24px.svg'
import downButton from '../assets/images/keyboard_arrow_down-24px.svg'

class MotivationExercise extends Component {

    static propTypes = {
        dispatchGetMotivations: PropTypes.func.isRequired,
        dispatchSaveMotivations: PropTypes.func.isRequired,
        dispatchSaveResultMotivations: PropTypes.func.isRequired,
        executionId: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            studentsId: -1,
            confirm: false,
            alert: { visible: false, textId: "alert.save", color: "success" },
            translationsVars: translationsVars,
            firstLoad: true,
            closeStartDescription: false,
            closeMotivationEvaluation: false,
            motivations: [],
            motivationsPairs: [],
            motivationsIdx: -1,
            evalLeft_idx: 0,
            evalRight_idx: 0,
            answers: [],
            initialState: true,
            userDataExists: false,
            userDataExistsId: 0,
            touched: "",
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) {

        let caseEvaluation = false;
        if (this.props.executionId !== -1 && (this.state.firstLoad || prevProps.executionId !== this.props.executionId)) {
            this.setState({ firstLoad: false });
            this.props.dispatchGetMotivations(this.props.auth.token, this.props.executionId);
            this.props.dispatchGetResult(this.props.auth.token, this.props.executionId);
        }

        if (prevProps.motivationsPairs !== this.props.motivationsPairs) {
            let motivationsPairs = [...this.props.motivationsPairs];
            let sumIdx = 0;
            motivationsPairs.forEach(mp => {
                if (mp.part1 && mp.part1.answer && mp.part1.answer !== 0 && mp.part2 && mp.part2.answer && mp.part2.answer !== 0) {
                    sumIdx++;
                }
            });

            if (sumIdx === motivationsPairs.length) { //case: user set all answers
                this.handleGoToEvaluation(this.props.motivationsPairs);
                caseEvaluation = true;
            } else if (sumIdx > 0) {
                this.setState({ userDataExists: true, userDataExistsId: sumIdx });
            } else {
                this.setState({ userDataExists: false, userDataExistsId: 0 });
            }

            this.setState({ motivationsPairs: motivationsPairs });

            if (this.props.motivationsPairs.length > 0) {
                this.setState({ motivationsIdx: 0 });
            }
        }



        if (this.props.result !== prevProps.result && this.props.result && !caseEvaluation && ((this.props.result.motivations && this.props.result.motivations.length > 0) || this.props.result.releaseTeacher === 1)) {
            this.handleGoToEvaluation();
        }
    }

    handleHideAlert = (e) => {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    };

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    };

    handlePDF() {
        generateMotivationExercisePDF(this.props, this.state);
    }

    handleCloseDescriptionPage = () => {
        let motivationsPairs = this.state.motivationsPairs;
        motivationsPairs.forEach((mp => {
            mp.part1.answer = 0;
            mp.part2.answer = 0;
        }))
        this.setState({ motivationsPairs: motivationsPairs, closeStartDescription: true })
    };

    handleContinue = () => {
        this.setState({ closeStartDescription: true, motivationsIdx: this.state.userDataExistsId })
    };

    handleGoToEvaluation = (motivationPairs = null) => {
        this.handleCloseMotivationPage(motivationPairs);
        this.setState({ closeStartDescription: true, closeMotivationEvaluation: true });
    }


    handleCloseMotivationPage = (mp) => {
        //sum for ranking
        let motivationsPairs = mp ? mp : this.state.motivationsPairs;
        let motivations = [];
        motivationsPairs.forEach((motivationPair) => {
            let elem2 = motivationsPairs.find(elem => elem.part2.id === motivationPair.part1.id);
            let sum = elem2 ? motivationPair.part1.answer + elem2.part2.answer : motivationPair.part1.answer;
            motivations.push({ id: motivationPair.part1.id, sum: sum, name: motivationPair.part1.name, description: motivationPair.part1.description })
        });
        motivations.sort((a, b) => { if (a.sum > b.sum) { return -1 } return 1 });
        this.setState({ motivations: motivations, closeMotivationEvaluation: true })
    }

    handleSave = () => {
        //save motivations for result page
        let mot = [];
        this.state.motivationsPairs.forEach((motivationPair) => {
            let elem2 = this.state.motivationsPairs.find(elem => elem.part2.id === motivationPair.part1.id);
            if (motivationPair.part1.answer) {
                let sum = elem2 && elem2.part2.answer ? motivationPair.part1.answer + elem2.part2.answer : motivationPair.part1.answer;
                mot.push({ id: motivationPair.part1.id, sum: sum, name: motivationPair.part1.name, description: motivationPair.part1.description })
            }
        });
        mot = mot.sort((a, b) => { if (a.sum > b.sum) { return -1 } return 1 });
        let idx = 1;
        let sum = mot.length > 0 ? mot[0].sum : 0;
        let motivations = [];
        mot.every((m) => {
            if (sum > m.sum) {
                sum = m.sum
                if (idx === 3 || motivations.length > 3) {
                    return false;
                }
                idx++;
            }
            motivations.push({ motivation: m.name, sum: sum });
            return true;
        })
        this.props.dispatchSaveResultMotivations(this.props.auth.token, this.props.executionId, motivations);

        this.props.dispatchSaveMotivations(this.props.auth.token, this.props.executionId, this.state.motivationsPairs);
        this.props.dispatchSaveResult(this.props.auth.token, this.props.executionId, []);
    }

    getAnswer = (e) => {
        let cell = e.target.id.split('.');
        if (cell.length === 3) {
            if (cell[1] === "left") {
                this.setState({ evalLeft_idx: parseInt(cell[2], 10) });
            } else {
                this.setState({ evalRight_idx: parseInt(cell[2], 10) });
            }
        }
    }

    saveAnswers = () => {
        let pairs = this.state.motivationsPairs;
        pairs[this.state.motivationsIdx].part1.answer = this.state.evalLeft_idx;
        pairs[this.state.motivationsIdx].part2.answer = this.state.evalRight_idx;
        this.props.dispatchSaveMotivations(this.props.auth.token, this.props.executionId, pairs);
        this.setState({ motivationsPairs: pairs });
    }

    nextQuestion = (e) => {
        this.saveAnswers();
        if (this.state.motivationsIdx + 1 < this.state.motivationsPairs.length) {
            this.setState({ motivationsIdx: this.state.motivationsIdx + 1, evalLeft_idx: 0, evalRight_idx: 0 });
        } else {
            this.handleCloseMotivationPage();
            this.handleSave();
        }
    }

    getId = (pos, text) => {
        let motivation = "motivationExercise.error";
        if (pos === 'left' && this.state.motivationsIdx > -1) {
            let motivationTmp = this.state.motivationsPairs[this.state.motivationsIdx].part1;
            motivation = motivationTmp ? text === "name" ? motivationTmp.name : motivationTmp.description : "motivationExercise.error";

        } else if (pos === 'right' && this.state.motivationsIdx > -1) {
            let motivationTmp = this.state.motivationsPairs[this.state.motivationsIdx].part2
            motivation = motivationTmp ? text === "name" ? motivationTmp.name : motivationTmp.description : "motivationExercise.error";
        }
        return motivation;
    }

    handleTouchStart = (e) => {
        e.stopPropagation()
        const id = e.target.id ? e.target.id : e.target.parentElement.id;
        this.setState({ touched: id !== this.state.touched ? id : "" });
    };

    onHtml = () => {
        this.setState({ touched: "" });
    }

    render() {
        if (this.props.loading) {
            return (
                <Container fluid role="main" className="content-margin">
                    <Container fluid className="p-0 fw-bold">
                        <h1><FormattedMessage id="motivationExercise.label" /></h1>
                        <Loading />
                    </Container>
                </Container>
            );

        }
        else if (this.props.executionId === -1) {
            return (
                <Container fluid role="main" className="content-margin">
                    <Container fluid className="p-0 fw-bold">
                        <h1><FormattedMessage id="motivationExercise.label" /></h1>

                        {/* Select Execution */}
                        <Row>
                            <Col xs="12" sm="6" md="6" lg="6" xl="3">
                                <ExecutionSelect className="mb-3 p-0" executionId={this.props.executions_id} past={true} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            );
        } else {
            let answer0_0 = 0, answer1_0 = 0, answer2_0 = 0, answer3_0 = 0, answer4_0 = 0, answer5_0 = 0, answer6_0 = 0, answer7_0 = 0;
            let answer0_1 = 0, answer1_1 = 0, answer2_1 = 0, answer3_1 = 0, answer4_1 = 0, answer5_1 = 0, answer6_1 = 0, answer7_1 = 0;

            if (this.state.closeStartDescription && this.state.closeMotivationEvaluation) {

                this.state.motivationsPairs.forEach((e, idx) => {
                    let mot1 = e.part1;
                    let mot2 = e.part2;
                    if (mot1.answer && mot2.answer) {
                        if (mot1.name === "motivationExercise.security.label" && mot2.name === "motivationExercise.leadership.label") {
                            answer0_0 = mot1.answer; answer0_1 = mot2.answer;
                        } else if (mot1.name === "motivationExercise.leadership.label" && mot2.name === "motivationExercise.workLife.label") {
                            answer1_0 = mot1.answer; answer1_1 = mot2.answer;
                        } else if (mot1.name === "motivationExercise.workLife.label" && mot2.name === "motivationExercise.challenge.label") {
                            answer2_0 = mot1.answer; answer2_1 = mot2.answer;
                        } else if (mot1.name === "motivationExercise.challenge.label" && mot2.name === "motivationExercise.dedication.label") {
                            answer3_0 = mot1.answer; answer3_1 = mot2.answer;
                        } else if (mot1.name === "motivationExercise.dedication.label" && mot2.name === "motivationExercise.founding.label") {
                            answer4_0 = mot1.answer; answer4_1 = mot2.answer;
                        } else if (mot1.name === "motivationExercise.founding.label" && mot2.name === "motivationExercise.knowledge.label") {
                            answer5_0 = mot1.answer; answer5_1 = mot2.answer;
                        } else if (mot1.name === "motivationExercise.knowledge.label" && mot2.name === "motivationExercise.independence.label") {
                            answer6_0 = mot1.answer; answer6_1 = mot2.answer;
                        } else if (mot1.name === "motivationExercise.independence.label" && mot2.name === "motivationExercise.security.label") {
                            answer7_0 = mot1.answer; answer7_1 = mot2.answer;
                        }
                    }
                })
            }

            return (
                <Container fluid role="main" className="content-margin">

                    <h1><FormattedMessage id="motivationExercise.label" /></h1>

                    {!this.state.closeStartDescription &&
                        <Container fluid className="start-description-container">
                            <FormattedMessage id="motivationExercise.start-description" values={{ br: <br /> }} />
                            <div className="next-button-container">
                                {
                                    !this.state.userDataExists &&
                                    <Button className="motivation-next-button" color="primary" onClick={this.handleCloseDescriptionPage}><FormattedMessage id={"verb-exercise.button.next"} /></Button>
                                }
                                {
                                    this.state.userDataExists &&
                                    <span>
                                        <Button className="motivation-next-button" color="primary" onClick={this.handleCloseDescriptionPage}><FormattedMessage id={"motivationExercise.button.restart"} /></Button>
                                        <Button color="primary" className="ms-3 motivation-next-button" onClick={this.handleContinue} ><FormattedMessage id="motivationExercise.button.continue" /></Button>
                                    </span>
                                }
                            </div>
                        </Container>
                    }
                    {this.state.closeStartDescription && !this.state.closeMotivationEvaluation &&
                        <Container fluid>
                            <div className="mb-2"><FormattedMessage id="motivationExercise.task" values={{ br: <br /> }} /> </div>
                            <Container fluid className="motivations-container p-3" >
                                <Row >
                                    <Col xs sm md />
                                    <Col xs="3" sm="3" md="2" className="text-center fw-bold text-ellipse">
                                        <FormattedMessage id={this.getId('left', 'name')} />
                                    </Col>
                                    <Col xs="3" sm="3" md="2" className="text-center fw-bold text-ellipse" >
                                        <FormattedMessage id={this.getId('right', 'name')} />
                                    </Col>
                                    <Col xs sm md />

                                </Row>
                                <Row className="bottomRow">
                                    <Col xs sm md style={{ textAlign: "justify" }}>
                                        <FormattedMessage id={this.getId('left', 'description')} />
                                    </Col>
                                    <Col xs="2" sm="2" md="2" className="motivation-evaluation pe-4">
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalLeft_idx === 5 ? " highlightLeft" : "")} id="eval.left.5" onClick={this.getAnswer}></Row>
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalLeft_idx >= 4 ? " highlightLeft" : "")} id="eval.left.4" onClick={this.getAnswer}></Row>
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalLeft_idx >= 3 ? " highlightLeft" : "")} id="eval.left.3" onClick={this.getAnswer}></Row>
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalLeft_idx >= 2 ? " highlightLeft" : "")} id="eval.left.2" onClick={this.getAnswer}></Row>
                                        <Row className={"motivation-evaluation-field" + (this.state.evalLeft_idx >= 1 ? " highlightLeft" : "")} id="eval.left.1" onClick={this.getAnswer}></Row>
                                    </Col>
                                    <Col xs="2" sm="2" md="2" className="border_bottom motivation-evaluation ps-4" >
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalRight_idx === 5 ? " highlight" : "")} id="eval.right.5" onClick={this.getAnswer}></Row>
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalRight_idx >= 4 ? " highlight" : "")} id="eval.right.4" onClick={this.getAnswer}></Row>
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalRight_idx >= 3 ? " highlight" : "")} id="eval.right.3" onClick={this.getAnswer}></Row>
                                        <Row className={"border-bottom border-white motivation-evaluation-field" + (this.state.evalRight_idx >= 2 ? " highlight" : "")} id="eval.right.2" onClick={this.getAnswer}></Row>
                                        <Row className={"motivation-evaluation-field" + (this.state.evalRight_idx >= 1 ? " highlight" : "")} id="eval.right.1" onClick={this.getAnswer}></Row>
                                    </Col>
                                    <Col xs sm md style={{ textAlign: "justify" }}>
                                        <FormattedMessage id={this.getId('right', 'description')} />
                                    </Col>
                                </Row>
                            </Container>
                            <Container fluid className="motivations-nextButton-container" >
                                <Button className="motivations-nextButton" color="primary" disabled={(this.state.evalRight_idx === this.state.evalLeft_idx || this.state.evalRight_idx === 0 || this.state.evalLeft_idx === 0) ? true : false} onClick={this.nextQuestion}><FormattedMessage id="button.next" /></Button>
                                {this.state.evalRight_idx === this.state.evalLeft_idx && this.state.evalRight_idx > 0 && this.state.evalLeft_idx > 0 &&
                                    <div className="hintText"><FormattedMessage id={"motivationExercise.sameAnswer"} /></div>
                                }
                            </Container>
                        </Container>
                    }
                    {this.state.closeStartDescription && this.state.closeMotivationEvaluation &&
                        <div onClick={this.onHtml}>
                            <Container className="sticky2 mb-1 bg-white">
                                <Row className="p-0 mb-4 m-0">
                                    <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind(this)} />
                                </Row>
                            </Container>
                            <Container fluid className="motivations-result">
                                <div id="motivationPolygon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 387.62 385.49"  >
                                        <g id="flaechen">
                                            {/* Sicherheit links (innen nach außen) */}
                                            <g>
                                                <polygon className={answer0_0 >= 1 ? "cls-blue" : "cls-0"} points="170.45 168.09 191.4 189.04 180.93 163.76 170.45 168.09"></polygon>
                                                <polygon className={answer0_0 >= 2 ? "cls-blue" : "cls-0"} points="146.52 144.16 169.88 167.52 180.64 163.06 168.96 134.87 146.52 144.16" />
                                                <polygon className={answer0_0 >= 3 ? "cls-blue" : "cls-0"} points="122.59 120.23 145.95 143.59 168.68 134.18 157 105.98 122.59 120.23" />
                                                <polygon className={answer0_0 >= 4 ? "cls-blue" : "cls-0"} points="98.66 96.3 122.02 119.66 156.71 105.29 145.03 77.1 98.66 96.3" />
                                                <polygon className={answer0_0 === 5 ? "cls-blue" : "cls-0"} points="144.75 76.4 133.07 48.21 74.73 72.37 98.09 95.73 144.75 76.4" />
                                                <title><FormattedMessage id="motivationExercise.security.description"></FormattedMessage></title>
                                            </g>
                                            {/* Führung rechts */}
                                            <g>
                                                <polygon className={answer0_1 >= 1 ? "cls-blue" : "cls-0"} points="192.1 188.76 192.1 159.13 181.62 163.47 192.1 188.76" />
                                                <polygon className={answer0_1 >= 2 ? "cls-blue" : "cls-0"} points="181.34 162.78 192.1 158.32 192.1 125.29 169.66 134.58 181.34 162.78" />
                                                <polygon className={answer0_1 >= 3 ? "cls-blue" : "cls-0"} points="169.37 133.89 192.1 124.47 192.1 91.44 157.69 105.69 169.37 133.89" />
                                                <polygon className={answer0_1 >= 4 ? "cls-blue" : "cls-0"} points="157.4 105 192.1 90.63 192.1 57.6 145.73 76.81 157.4 105" />
                                                <polygon className={answer0_1 === 5 ? "cls-blue" : "cls-0"} points="145.44 76.11 192.1 56.79 192.1 23.76 133.76 47.92 145.44 76.11" />
                                                <title><FormattedMessage id="motivationExercise.leadership.description"></FormattedMessage></title>
                                            </g>
                                            {/* Führung links */}
                                            <g>
                                                <polygon className={answer1_0 >= 1 ? "cls-blue" : "cls-0"} points="194.1 159.13 194.1 188.76 204.57 163.47 194.1 159.13" />
                                                <polygon className={answer1_0 >= 2 ? "cls-blue" : "cls-0"} points="216.54 134.58 194.1 125.29 194.1 158.32 204.86 162.78 216.54 134.58" />
                                                <polygon className={answer1_0 >= 3 ? "cls-blue" : "cls-0"} points="228.5 105.69 194.1 91.44 194.1 124.47 216.82 133.89 228.5 105.69" />
                                                <polygon className={answer1_0 >= 4 ? "cls-blue" : "cls-0"} points="240.47 76.81 194.1 57.6 194.1 90.63 228.79 105 240.47 76.81" />
                                                <polygon className={answer1_0 >= 5 ? "cls-blue" : "cls-0"} points="252.43 47.92 194.1 23.76 194.1 56.79 240.75 76.11 252.43 47.92" />
                                                <title><FormattedMessage id="motivationExercise.leadership.description"></FormattedMessage></title>
                                            </g>
                                            {/* workLife rechts */}
                                            <g>
                                                <polygon className={answer1_1 >= 1 ? "cls-blue" : "cls-0"} points="194.79 189.04 215.74 168.09 205.26 163.76 194.79 189.04" />
                                                <polygon className={answer1_1 >= 2 ? "cls-blue" : "cls-0"} points="205.55 163.06 216.31 167.52 239.67 144.16 217.23 134.87 205.55 163.06" />
                                                <polygon className={answer1_1 >= 3 ? "cls-blue" : "cls-0"} points="217.52 134.18 240.25 143.59 263.6 120.23 229.2 105.98 217.52 134.18" />
                                                <polygon className={answer1_1 >= 4 ? "cls-blue" : "cls-0"} points="229.48 105.29 264.18 119.66 287.53 96.3 241.16 77.1 229.48 105.29" />
                                                <polygon className={answer1_1 >= 5 ? "cls-blue" : "cls-0"} points="288.11 95.73 311.46 72.37 253.13 48.21 241.45 76.4 288.11 95.73" />
                                                <title><FormattedMessage id="motivationExercise.workLife.description"></FormattedMessage></title>
                                            </g>
                                            {/* workLife links */}
                                            <g>
                                                <polygon className={answer2_0 >= 1 ? "cls-blue" : "cls-0"} points="196.2 190.46 221.49 179.98 217.15 169.51 196.2 190.46" />
                                                <polygon className={answer2_0 >= 2 ? "cls-blue" : "cls-0"} points="217.73 168.93 222.19 179.7 250.38 168.02 241.09 145.58 217.73 168.93" />
                                                <polygon className={answer2_0 >= 3 ? "cls-blue" : "cls-0"} points="265.02 121.65 241.66 145 251.07 167.73 279.27 156.05 265.02 121.65" />
                                                <polygon className={answer2_0 >= 4 ? "cls-blue" : "cls-0"} points="288.94 97.72 265.59 121.07 279.96 155.77 308.15 144.09 288.94 97.72" />
                                                <polygon className={answer2_0 >= 5 ? "cls-blue" : "cls-0"} points="308.85 143.8 337.04 132.12 312.88 73.79 289.52 97.14 308.85 143.8" />
                                                <title><FormattedMessage id="motivationExercise.workLife.description"></FormattedMessage></title>
                                            </g>
                                            {/* Herausforderung rechts */}
                                            <g>
                                                <polygon className={answer2_1 >= 1 ? "cls-blue" : "cls-0"} points="221.78 180.68 196.49 191.15 226.12 191.15 221.78 180.68" />
                                                <polygon className={answer2_1 >= 2 ? "cls-blue" : "cls-0"} points="250.67 168.71 222.47 180.39 226.93 191.15 259.96 191.15 250.67 168.71" />
                                                <polygon className={answer2_1 >= 3 ? "cls-blue" : "cls-0"} points="279.55 156.75 251.36 168.42 260.77 191.15 293.81 191.15 279.55 156.75" />
                                                <polygon className={answer2_1 >= 4 ? "cls-blue" : "cls-0"} points="308.44 144.78 280.25 156.46 294.62 191.15 327.65 191.15 308.44 144.78" />
                                                <polygon className={answer2_1 >= 5 ? "cls-blue" : "cls-0"} points="309.13 144.49 328.46 191.15 361.49 191.15 337.33 132.82 309.13 144.49" />
                                                <polygon className={answer2_1 >= 5 ? "cls-blue" : "cls-0"} points="309.13 144.49 328.46 191.15 361.49 191.15 337.33 132.82 309.13 144.49" />
                                                <polygon className={answer2_1 >= 5 ? "cls-blue" : "cls-0"} points="309.13 144.49 328.46 191.15 361.49 191.15 337.33 132.82 309.13 144.49" />
                                                <polygon className={answer2_1 >= 5 ? "cls-blue" : "cls-0"} points="309.13 144.49 328.46 191.15 361.49 191.15 337.33 132.82 309.13 144.49" />
                                                <polygon className={answer2_1 >= 5 ? "cls-blue" : "cls-0"} points="309.13 144.49 328.46 191.15 361.49 191.15 337.33 132.82 309.13 144.49" />
                                                <title><FormattedMessage id="motivationExercise.challenge.description"></FormattedMessage></title>
                                            </g>
                                            {/* Herausforderung links */}
                                            <g>
                                                <polygon className={answer3_0 >= 1 ? "cls-blue" : "cls-0"} points="226.12 193.15 196.49 193.15 221.78 203.63 226.12 193.15" />
                                                <polygon className={answer3_0 >= 2 ? "cls-blue" : "cls-0"} points="259.96 193.15 226.93 193.15 222.47 203.91 250.67 215.59 259.96 193.15" />
                                                <polygon className={answer3_0 >= 3 ? "cls-blue" : "cls-0"} points="293.81 193.15 260.77 193.15 251.36 215.88 279.55 227.56 293.81 193.15" />
                                                <polygon className={answer3_0 >= 4 ? "cls-blue" : "cls-0"} points="327.65 193.15 294.62 193.15 280.25 227.84 308.44 239.52 327.65 193.15" />
                                                <polygon className={answer3_0 >= 5 ? "cls-blue" : "cls-0"} points="309.13 239.81 337.33 251.49 361.49 193.15 328.46 193.15 309.13 239.81" />
                                                <title><FormattedMessage id="motivationExercise.challenge.description"></FormattedMessage></title>
                                            </g>
                                            {/* Hingabe rechts */}
                                            <g>
                                                <polygon className={answer3_1 >= 1 ? "cls-blue" : "cls-0"} points="217.15 214.79 221.49 204.32 196.2 193.84 217.15 214.79" />
                                                <polygon className={answer3_1 >= 2 ? "cls-blue" : "cls-0"} points="250.38 216.28 222.19 204.61 217.73 215.37 241.09 238.73 250.38 216.28" />
                                                <polygon className={answer3_1 >= 3 ? "cls-blue" : "cls-0"} points="279.27 228.25 251.07 216.57 241.66 239.3 265.02 262.66 279.27 228.25" />
                                                <polygon className={answer3_1 >= 4 ? "cls-blue" : "cls-0"} points="308.15 240.22 279.96 228.54 265.59 263.23 288.95 286.59 308.15 240.22" />
                                                <polygon className={answer3_1 >= 5 ? "cls-blue" : "cls-0"} points="289.52 287.16 312.88 310.52 337.04 252.18 308.85 240.5 289.52 287.16" />
                                                <title><FormattedMessage id="motivationExercise.dedication.description"></FormattedMessage></title>
                                            </g>
                                            {/* Hingabe links */}
                                            <g>
                                                <polygon className={answer4_0 >= 1 ? "cls-blue" : "cls-0"} points="205.26 220.55 215.74 216.21 194.79 195.26 205.26 220.55" />
                                                <polygon className={answer4_0 >= 2 ? "cls-blue" : "cls-0"} points="239.67 240.14 216.31 216.78 205.55 221.24 217.23 249.44 239.67 240.14" />
                                                <polygon className={answer4_0 >= 3 ? "cls-blue" : "cls-0"} points="263.6 264.07 240.25 240.71 217.52 250.13 229.2 278.32 263.6 264.07" />
                                                <polygon className={answer4_0 >= 4 ? "cls-blue" : "cls-0"} points="287.53 288 264.18 264.64 229.48 279.01 241.16 307.21 287.53 288" />
                                                <polygon className={answer4_0 >= 5 ? "cls-blue" : "cls-0"} points="241.45 307.9 253.13 336.1 311.46 311.93 288.11 288.57 241.45 307.9" />
                                                <title><FormattedMessage id="motivationExercise.dedication.description"></FormattedMessage></title>
                                            </g>
                                            {/* Gründung rechts */}
                                            <g>
                                                <polygon className={answer4_1 >= 1 ? "cls-blue" : "cls-0"} points="194.1 195.55 194.1 225.18 204.57 220.84 194.1 195.55" />
                                                <polygon className={answer4_1 >= 2 ? "cls-blue" : "cls-0"} points="204.86 221.53 194.1 225.99 194.1 259.02 216.54 249.72 204.86 221.53" />
                                                <polygon className={answer4_1 >= 3 ? "cls-blue" : "cls-0"} points="216.82 250.42 194.1 259.83 194.1 292.86 228.5 278.61 216.82 250.42" />
                                                <polygon className={answer4_1 >= 4 ? "cls-blue" : "cls-0"} points="228.79 279.3 194.1 293.67 194.1 326.7 240.47 307.5 228.79 279.3" />
                                                <polygon className={answer4_1 >= 5 ? "cls-blue" : "cls-0"} points="194.1 327.51 194.1 360.55 252.43 336.38 240.75 308.19 194.1 327.51" />
                                                <title><FormattedMessage id="motivationExercise.founding.description"></FormattedMessage></title>
                                            </g>
                                            {/* Gründung links */}
                                            <g>
                                                <polygon className={answer5_0 >= 1 ? "cls-blue" : "cls-0"} points="192.1 225.18 192.1 195.55 181.62 220.84 192.1 225.18" />
                                                <polygon className={answer5_0 >= 2 ? "cls-blue" : "cls-0"} points="169.66 249.72 192.1 259.02 192.1 225.99 181.34 221.53 169.66 249.72" />
                                                <polygon className={answer5_0 >= 2 ? "cls-blue" : "cls-0"} points="169.66 249.72 192.1 259.02 192.1 225.99 181.34 221.53 169.66 249.72" />
                                                <polygon className={answer5_0 >= 2 ? "cls-blue" : "cls-0"} points="169.66 249.72 192.1 259.02 192.1 225.99 181.34 221.53 169.66 249.72" />
                                                <polygon className={answer5_0 >= 2 ? "cls-blue" : "cls-0"} points="169.66 249.72 192.1 259.02 192.1 225.99 181.34 221.53 169.66 249.72" />
                                                <polygon className={answer5_0 >= 2 ? "cls-blue" : "cls-0"} points="169.66 249.72 192.1 259.02 192.1 225.99 181.34 221.53 169.66 249.72" />
                                                <polygon className={answer5_0 >= 3 ? "cls-blue" : "cls-0"} points="157.69 278.61 192.1 292.86 192.1 259.83 169.37 250.41 157.69 278.61" />
                                                <polygon className={answer5_0 >= 4 ? "cls-blue" : "cls-0"} points="145.73 307.5 192.1 326.7 192.1 293.67 157.4 279.3 145.73 307.5" />
                                                <polygon className={answer5_0 >= 5 ? "cls-blue" : "cls-0"} points="133.76 336.38 192.1 360.55 192.1 327.51 145.44 308.19 133.76 336.38" />
                                                <title><FormattedMessage id="motivationExercise.founding.description"></FormattedMessage></title>
                                            </g>
                                            {/* Wissen rechts */}
                                            <g>
                                                <polygon className={answer5_1 >= 1 ? "cls-blue" : "cls-0"} points="191.4 195.26 170.45 216.21 180.93 220.55 191.4 195.26" />
                                                <polygon className={answer5_1 >= 2 ? "cls-blue" : "cls-0"} points="180.64 221.24 169.88 216.78 146.52 240.14 168.96 249.44 180.64 221.24" />
                                                <polygon className={answer5_1 >= 3 ? "cls-blue" : "cls-0"} points="168.68 250.13 145.95 240.71 122.59 264.07 157 278.32 168.68 250.13" />
                                                <polygon className={answer5_1 >= 4 ? "cls-blue" : "cls-0"} points="156.71 279.01 122.02 264.64 98.66 288 145.03 307.21 156.71 279.01" />
                                                <polygon className={answer5_1 >= 5 ? "cls-blue" : "cls-0"} points="98.09 288.57 74.73 311.93 133.07 336.1 144.75 307.9 98.09 288.57" />
                                                <title><FormattedMessage id="motivationExercise.knowledge.description"></FormattedMessage></title>
                                            </g>
                                            {/* Wissen links */}
                                            <g>
                                                <polygon className={answer6_0 >= 1 ? "cls-blue" : "cls-0"} points="189.99 193.84 164.7 204.32 169.04 214.79 189.99 193.84" />
                                                <polygon className={answer6_0 >= 2 ? "cls-blue" : "cls-0"} points="168.47 215.37 164.01 204.61 135.81 216.28 145.11 238.72 168.47 215.37" />
                                                <polygon className={answer6_0 >= 3 ? "cls-blue" : "cls-0"} points="144.53 239.3 135.12 216.57 106.93 228.25 121.18 262.66 144.53 239.3" />
                                                <polygon className={answer6_0 >= 4 ? "cls-blue" : "cls-0"} points="120.6 263.23 106.23 228.54 78.04 240.22 97.25 286.59 120.6 263.23" />
                                                <polygon className={answer6_0 >= 5 ? "cls-blue" : "cls-0"} points="77.35 240.5 49.15 252.18 73.32 310.52 96.67 287.16 77.35 240.5" />
                                                <title><FormattedMessage id="motivationExercise.knowledge.description"></FormattedMessage></title>
                                            </g>
                                            {/* Unabhängigkeit rechts */}
                                            <g>
                                                <polygon className={answer6_1 >= 1 ? "cls-blue" : "cls-0"} points="189.7 193.15 160.07 193.15 164.41 203.63 189.7 193.15" />
                                                <polygon className={answer6_1 >= 2 ? "cls-blue" : "cls-0"} points="126.23 193.15 135.53 215.59 163.72 203.91 159.26 193.15 126.23 193.15" />
                                                <polygon className={answer6_1 >= 3 ? "cls-blue" : "cls-0"} points="106.64 227.56 134.83 215.88 125.42 193.15 92.39 193.15 106.64 227.56" />
                                                <polygon className={answer6_1 >= 4 ? "cls-blue" : "cls-0"} points="77.75 239.52 105.95 227.84 91.58 193.15 58.55 193.15 77.75 239.52" />
                                                <polygon className={answer6_1 >= 5 ? "cls-blue" : "cls-0"} points="57.73 193.15 24.7 193.15 48.87 251.49 77.06 239.81 57.73 193.15" />
                                                <title><FormattedMessage id="motivationExercise.independence.description"></FormattedMessage></title>
                                            </g>
                                            {/* Unabhängikeit links */}
                                            <g>
                                                <polygon className={answer7_0 >= 1 ? "cls-blue" : "cls-0"} points="164.41 180.68 160.07 191.15 189.7 191.15 164.41 180.68" />
                                                <polygon className={answer7_0 >= 2 ? "cls-blue" : "cls-0"} points="126.23 191.15 159.26 191.15 163.72 180.39 135.53 168.71 126.23 191.15" />
                                                <polygon className={answer7_0 >= 3 ? "cls-blue" : "cls-0"} points="92.39 191.15 125.42 191.15 134.83 168.42 106.64 156.75 92.39 191.15" />
                                                <polygon className={answer7_0 >= 4 ? "cls-blue" : "cls-0"} points="58.55 191.15 91.58 191.15 105.95 156.46 77.75 144.78 58.55 191.15" />
                                                <polygon className={answer7_0 >= 5 ? "cls-blue" : "cls-0"} points="77.06 144.49 48.87 132.82 24.7 191.15 57.73 191.15 77.06 144.49" />
                                                <polygon className={answer7_0 >= 5 ? "cls-blue" : "cls-0"} points="77.06 144.49 48.87 132.82 24.7 191.15 57.73 191.15 77.06 144.49" />
                                                <polygon className={answer7_0 >= 5 ? "cls-blue" : "cls-0"} points="77.06 144.49 48.87 132.82 24.7 191.15 57.73 191.15 77.06 144.49" />
                                                <polygon className={answer7_0 >= 5 ? "cls-blue" : "cls-0"} points="77.06 144.49 48.87 132.82 24.7 191.15 57.73 191.15 77.06 144.49" />
                                                <polygon className={answer7_0 >= 5 ? "cls-blue" : "cls-0"} points="77.06 144.49 48.87 132.82 24.7 191.15 57.73 191.15 77.06 144.49" />
                                                <title><FormattedMessage id="motivationExercise.independence.description"></FormattedMessage></title>
                                            </g>
                                            {/* Sicherheit rechts */}
                                            <g>
                                                <polygon className={answer7_1 >= 1 ? "cls-blue" : "cls-0"} points="169.04 169.51 164.7 179.98 189.99 190.46 169.04 169.51" />
                                                <polygon className={answer7_1 >= 2 ? "cls-blue" : "cls-0"} points="135.81 168.02 164.01 179.7 168.47 168.93 145.11 145.58 135.81 168.02" />
                                                <polygon className={answer7_1 >= 3 ? "cls-blue" : "cls-0"} points="106.93 156.05 135.12 167.73 144.53 145 121.18 121.65 106.93 156.05" />
                                                <polygon className={answer7_1 >= 4 ? "cls-blue" : "cls-0"} points="78.04 144.09 106.23 155.77 120.6 121.07 97.25 97.72 78.04 144.09" />
                                                <polygon className={answer7_1 >= 4 ? "cls-blue" : "cls-0"} points="78.04 144.09 106.23 155.77 120.6 121.07 97.25 97.72 78.04 144.09" />
                                                <polygon className={answer7_1 >= 4 ? "cls-blue" : "cls-0"} points="78.04 144.09 106.23 155.77 120.6 121.07 97.25 97.72 78.04 144.09" />
                                                <polygon className={answer7_1 >= 4 ? "cls-blue" : "cls-0"} points="78.04 144.09 106.23 155.77 120.6 121.07 97.25 97.72 78.04 144.09" />
                                                <polygon className={answer7_1 >= 4 ? "cls-blue" : "cls-0"} points="78.04 144.09 106.23 155.77 120.6 121.07 97.25 97.72 78.04 144.09" />
                                                <polygon className={answer7_1 >= 5 ? "cls-blue" : "cls-0"} points="96.67 97.14 73.32 73.79 49.15 132.12 77.35 143.8 96.67 97.14" />
                                                <title><FormattedMessage id="motivationExercise.security.description"></FormattedMessage></title>
                                            </g>
                                        </g>
                                        <g id="netz">
                                            <line className="cls-2" x1="193.1" y1="361.37" x2="193.1" y2="22.94" />
                                            <line className="cls-2" x1="312.75" y1="311.8" x2="73.44" y2="72.5" />
                                            <line className="cls-2" x1="362.31" y1="192.15" x2="23.88" y2="192.15" />
                                            <line className="cls-2" x1="312.75" y1="72.5" x2="73.44" y2="311.8" />
                                            <line className="cls-3" x1="159.25" y1="192.15" x2="169.17" y2="168.22" />
                                            <line className="cls-3" x1="145.24" y1="144.29" x2="125.41" y2="192.15" />
                                            <line className="cls-3" x1="169.17" y1="168.22" x2="159.25" y2="192.15" />
                                            <line className="cls-3" x1="125.41" y1="192.15" x2="145.24" y2="144.29" />
                                            <line className="cls-3" x1="121.31" y1="120.36" x2="91.57" y2="192.15" />
                                            <polygon className="cls-3" points="73.44 72.5 23.88 192.15 73.44 311.8 193.1 361.37 312.75 311.8 362.31 192.15 312.75 72.5 193.1 22.94 73.44 72.5" />
                                            <line className="cls-3" x1="57.73" y1="192.15" x2="97.38" y2="96.43" />
                                            <line className="cls-3" x1="91.57" y1="192.15" x2="121.31" y2="120.36" />
                                            <line className="cls-3" x1="97.38" y1="96.43" x2="57.73" y2="192.15" />
                                            <line className="cls-3" x1="159.25" y1="192.15" x2="169.17" y2="216.08" />
                                            <line className="cls-3" x1="145.24" y1="240.01" x2="125.41" y2="192.15" />
                                            <line className="cls-3" x1="121.31" y1="263.94" x2="91.57" y2="192.15" />
                                            <line className="cls-3" x1="57.73" y1="192.15" x2="97.38" y2="287.87" />
                                            <line className="cls-3" x1="169.17" y1="216.08" x2="193.1" y2="225.99" />
                                            <line className="cls-3" x1="193.1" y1="259.84" x2="145.24" y2="240.01" />
                                            <line className="cls-3" x1="193.1" y1="293.68" x2="121.31" y2="263.94" />
                                            <line className="cls-3" x1="97.38" y1="287.87" x2="193.1" y2="327.52" />
                                            <line className="cls-3" x1="193.1" y1="225.99" x2="217.03" y2="216.08" />
                                            <line className="cls-3" x1="240.96" y1="240.01" x2="193.1" y2="259.84" />
                                            <line className="cls-3" x1="264.89" y1="263.94" x2="193.1" y2="293.68" />
                                            <line className="cls-3" x1="193.1" y1="327.52" x2="288.82" y2="287.87" />
                                            <line className="cls-3" x1="217.03" y1="216.08" x2="226.94" y2="192.15" />
                                            <line className="cls-3" x1="260.78" y1="192.15" x2="240.96" y2="240.01" />
                                            <line className="cls-3" x1="294.63" y1="192.15" x2="264.89" y2="263.94" />
                                            <line className="cls-3" x1="288.82" y1="287.87" x2="328.47" y2="192.15" />
                                            <line className="cls-3" x1="226.94" y1="192.15" x2="217.03" y2="168.22" />
                                            <line className="cls-3" x1="240.96" y1="144.29" x2="260.78" y2="192.15" />
                                            <line className="cls-3" x1="264.89" y1="120.36" x2="294.63" y2="192.15" />
                                            <line className="cls-3" x1="328.47" y1="192.15" x2="288.82" y2="96.43" />
                                            <line className="cls-3" x1="217.03" y1="168.22" x2="193.1" y2="158.31" />
                                            <line className="cls-3" x1="193.1" y1="124.47" x2="240.96" y2="144.29" />
                                            <line className="cls-3" x1="193.1" y1="90.62" x2="264.89" y2="120.36" />
                                            <line className="cls-3" x1="288.82" y1="96.43" x2="193.1" y2="56.78" />
                                            <line className="cls-3" x1="193.1" y1="158.31" x2="169.17" y2="168.22" />
                                            <line className="cls-3" x1="145.24" y1="144.29" x2="193.1" y2="124.47" />
                                            <line className="cls-3" x1="121.31" y1="120.36" x2="193.1" y2="90.62" />
                                            <line className="cls-3" x1="193.1" y1="56.78" x2="97.38" y2="96.43" />
                                            <line className="cls-3" x1="48.66" y1="132.33" x2="337.53" y2="251.98" />
                                            <line className="cls-3" x1="48.66" y1="251.98" x2="337.53" y2="132.33" />
                                            <line className="cls-3" x1="133.27" y1="336.58" x2="252.92" y2="47.72" />
                                            <line className="cls-3" x1="252.92" y1="336.58" x2="133.27" y2="47.72" />
                                        </g>
                                        <g id="text">
                                            <text className="cls-4" transform="translate(74.93 51.82) rotate(-22.5)">Sicher-<tspan x="0" y="14.14">heit</tspan></text>
                                            <text x="-6.66" y="-6.16" />
                                            <text className="cls-4" transform="translate(137.22 41.33) rotate(-22.5)">Führung</text>
                                            <text className="cls-4" transform="translate(10.34 176.41) rotate(-67.5)">Unabhän-<tspan x="0" y="14.14">gigkeit</tspan></text>
                                            <text className="cls-4" transform="translate(33.9 119.53) rotate(-67.5)">Sicher-<tspan x="0" y="14.14">heit</tspan></text>
                                            <text x="-6.66" y="-6.16" /><text className="cls-4" transform="translate(65.78 304.89) rotate(-112.5)">Wissen</text>
                                            <text className="cls-4" transform="translate(29.15 253.43) rotate(-112.5)">Unabhän-<tspan x="0" y="14.14">gigkeit</tspan></text>
                                            <text x="-6.66" y="-6.16" />
                                            <text className="cls-4" transform="translate(182.82 361.82) rotate(-157.5)">Gründung</text>
                                            <text x="-6.66" y="-6.16" /><text className="cls-4" transform="translate(125.95 338.27) rotate(-157.5)">Wissen</text>
                                            <text x="-6.66" y="-6.16" /><text className="cls-4" transform="translate(305.78 319.46) rotate(157.5)">Hingabe</text>
                                            <text x="-6.66" y="-6.16" /><text className="cls-4" transform="translate(248.91 343.01) rotate(157.5)">Gründung</text>
                                            <text className="cls-4" transform="translate(375.89 207.83) rotate(112.5)">Herausfor-<tspan x="0" y="14.14">derung</tspan></text>
                                            <text x="-6.66" y="-6.16" /><text className="cls-4" transform="translate(339.27 259.29) rotate(112.5)">Hingabe</text>
                                            <text className="cls-4" transform="translate(333.41 74.04) rotate(67.5)"><tspan className="cls-5">W</tspan><tspan x="10.91" y="0">ork-Life-</tspan><tspan x="0" y="14.14">Balance</tspan></text>
                                            <text className="cls-4" transform="translate(356.97 130.91) rotate(67.5)">Herausfor-<tspan x="0" y="14.14">derung</tspan></text><text x="-6.66" y="-6.16" />
                                            <text className="cls-4" transform="translate(203.42 22.41) rotate(22.5)">Führung</text>
                                            <text className="cls-4" transform="translate(265.7 32.9) rotate(22.5)"><tspan className="cls-5">W</tspan><tspan x="10.91" y="0">ork-Life-</tspan><tspan x="0" y="14.14">Balance</tspan></text>
                                        </g>
                                    </svg>
                                </div>
                                <ListGroup>
                                    {this.state.motivations.map((mot, idx) => {
                                        return <ListGroupItem className="motivation-list" key={"evallist." + idx} id={"evallist." + idx} title={this.props.intl.formatMessage({ id: mot.description })} onTouchStart={this.handleTouchStart} onClick={this.handleTouchStart}>
                                            <img src={this.state.touched === "evallist." + idx ? downButton : rightButton} />
                                            <FormattedMessage id={mot.name} />({mot.sum})
                                            {this.state.touched === "evallist." + idx && (
                                                <p>
                                                    <span className="cls-4"><FormattedMessage id={mot.description} ></FormattedMessage></span>
                                                </p>
                                            )}
                                        </ListGroupItem>
                                    })}
                                </ListGroup>

                            </Container>
                        </div >

                    }
                </Container >
            );
        }
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        motivationsPairs: state.motivations.motivationsPairs,
        result: state.kompoGResult.result,
        loading: state.kompoGResult.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetMotivations: (token, executionId) => {
            dispatch(motivationActions.getMotivations(token, executionId, null, null));
        },
        dispatchSaveMotivations: (token, executionId, motAnswers) => {
            dispatch(motivationActions.saveMotivations(token, executionId, motAnswers));
        },
        dispatchSaveResultMotivations: (token, executionId, motivations) => {
            dispatch(motivationActions.saveResultMotivations(token, executionId, motivations));
        },
        dispatchSaveResult: (token, executionId, result) => {
            dispatch(kompoGResultActions.saveResult(token, executionId, result));
        },
        dispatchGetResult: (token, executionId) => {
            dispatch(kompoGResultActions.getResult(token, executionId, null, null));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(MotivationExercise));

