import * as React from 'react';
import { injectIntl, FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import config from '../config/config';
import { Button, Label, Tooltip } from 'reactstrap';
import Loading from './Loading';
import moment from 'moment';
import { CheckAuthorization } from '../models/Authorization';
import { authorizationRoles } from '../config/authorization';
import DisplaySchool from './DisplaySchool';
import DisplaySchoolyears from './DisplaySchoolyears';
import DisplayRoles from './DisplayRoles';

class TableBody extends React.Component {

    static propTypes = {
        data: PropTypes.array, //[{editabe:true/false}]
        show: PropTypes.array, //[""]
        info: PropTypes.func,
        delete: PropTypes.func,
        edit: PropTypes.func,
        reset: PropTypes.func,
        inquiry: PropTypes.func,
        group: PropTypes.func,
        foreignUsersPdf: PropTypes.func,
        select: PropTypes.func,
        print: PropTypes.func,
        printG: PropTypes.func,
        release: PropTypes.func,
        loading: PropTypes.bool,
        className: PropTypes.string,
        withTime: PropTypes.bool,
        checkEditable: PropTypes.bool, // if checkEditable is true and ["editable"] is false then changed the pencil icon to info icon
        checkSchool: PropTypes.bool,
        highlightLines: PropTypes.array,
        editField: PropTypes.bool,
        editChange: PropTypes.func,
    };

    static defaultProps = {
        withTime: false,
        highlightLines: []
    }

    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: -1,
        }
    }

    toggleTooltip(name, e) {
        this.setState({ tooltipOpen: name });
    }

    getData(key, trData, index) {
        if (key.split(".")[++index] && trData) {
            return this.getData(key, trData[key.split(".")[index]], index);
        } else {
            return trData;
        }
    }

    handleChange = (e) => {
        this.props.editChange(this.props.data[e.target.id], e.target.value);
    }

    render() {

        if (this.props.loading) {
            return <tbody><tr><td colSpan="99" className="text-center fw-bold"><Loading /></td></tr></tbody>
        }
        /* no content */
        if (this.props.data.length === 0) {
            return <tbody><tr><td colSpan="99" className="text-center fw-bold"><FormattedMessage id="tablebody.label.nocontent" /></td></tr></tbody>
        }

        // (!trData.hasOwnProperty('skillsProfile') || (trData.skillsProfile && trData.skillsProfile.approved === 1) || (this.props.auth.roles.find(el => el===4)===undefined || trData.releaseTeacher===1 ))) &&

        return <tbody className={this.props.className}>{this.props.data.map((trData, trIndex) => {

            let ownSchool = false;
            ownSchool = (trData.school && trData.school.own) || !this.props.checkSchool;

            ownSchool = this.props.auth.roles.find(e => e === 3) !== undefined ? true : ownSchool;
            let procedure = -1;
            if (trData.procedure) {
                if (trData.procedure.length > 1) {
                    procedure = 0; //KompoBoth
                } else {
                    procedure = trData.procedure.id;
                }
            }
            return <tr key={"tablebodytr" + trIndex} className={this.props.highlightLines.filter(hl => hl === trIndex).length > 0 ? 'highlight' : ''}>{
                this.props.show.map((key, tdIndex) => {

                    let tdData = null;

                    if (key.split(".").length - 1 !== 0) {
                        tdData = this.getData(key, trData[key.split(".")[0]], 0);
                    } else if (trData.hasOwnProperty(key)) {
                        tdData = trData[key];
                    }

                    if (key === "school") {
                        //if key school use component Displayschool
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div"><DisplaySchool school={tdData} name={trIndex.toString()} /></div></td>
                    } else if (key === "schoolyears" && typeof tdData === 'object') {
                        //if key schoolyear use component DisplaySchoolyears 
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div"><span><DisplaySchoolyears schoolyears={tdData} name={trIndex.toString()} /></span></div></td>
                    } else if (key === 'department') {
                        //if key department display the name value directly
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div">{tdData.name}</div></td>
                    } else if (key === "roles") {
                        //if key roles use component DisplayRoles
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><DisplayRoles roles={tdData} name={trIndex.toString()} /></td>
                    } else if (key === "certified" && tdData === 1) {
                        //if key certified display icon for certified
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="p-1"><div className="td-div"><img className="icon-certified" src={require('../assets/images/BWHW_Logo_Stempel.png')} alt={this.props.intl.formatMessage({ id: "tablebody.alt.certified" })} /></div></td>
                    } else if (key.indexOf('icon') > -1) {
                        // display any other icon
                        tdData = tdData.toString();
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="p-1"><div className="td-div justify-content-center"><img className="tb-icon" src={tdData} alt="img" /></div></td>
                    } else if (typeof tdData === 'object' && tdData && tdData.name) {
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div"><FormattedMessage id={tdData.name} /></div></td>
                    } else if (key === "textarea" && tdData) {
                        return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div"><textarea id={trIndex} disabled={!tdData.edit} className="tableTextarea" onChange={this.handleChange.bind(this)} value={tdData.text} /></div></td>
                    } else if (tdData) {
                        //.replace(" ","T") only need for IE11
                        tdData = tdData.toString();
                        let datetime = moment(tdData.replace(" ", "T"), config.dateFormat.format, true).isValid() ? moment(tdData.replace(" ", "T"), config.dateFormat.format, true) : null;

                        if (datetime && this.props.withTime) {
                            return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div"><FormattedDate value={datetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={datetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /> - <FormattedTime value={datetime} hour={config.dateFormat.hour} minute={config.dateFormat.minute} /></div></td>
                        } else if (datetime) {
                            return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div"><FormattedDate value={datetime} weekday={config.dateFormat.weekday} />. <FormattedDate value={datetime} month={config.dateFormat.month} year={config.dateFormat.year} day={config.dateFormat.day} /></div></td>
                        } else {
                            return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"><div className="td-div"><span>{tdData}</span></div></td>
                        }
                    }

                    return <td key={"tablebodytd" + trIndex + "_" + tdIndex} className="py-1"></td>
                })}

                {//Edit / Delete               
                    (this.props.edit || this.props.delete || this.props.inquiry || this.props.group || this.props.info || this.props.print || this.props.printG || this.props.release) &&
                    <td key={"tablebodyedit" + trIndex} className="table-border-left-action p-0">
                        <div id="tablebodyaction" className="d-flex justify-content-left m-0 p-0">
                            { //Group
                                this.props.group && ownSchool &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipGroup" + trIndex}><FormattedMessage id="tablebody.label.group" /></Label>
                                    <Button color="link" id={"TooltipGroup" + trIndex} name={"TooltipGroup" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipGroup" + trIndex)} className="p-0 m-0 group" onClick={() => { this.props.group(trData["id"]); this.toggleTooltip("") }} ></Button>
                                </div>
                            }
                            { //Edit                            
                                this.props.edit && (!this.props.checkEditable || (this.props.checkEditable && trData["editable"])) && ownSchool ?
                                    <div className="td-div">
                                        <Label className="d-none" for={"TooltipEdit" + trIndex}><FormattedMessage id="tablebody.label.edit" /></Label>
                                        <Button color="link" id={"TooltipEdit" + trIndex} name={"TooltipEdit" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipEdit" + trIndex)} className="p-0 m-0 edit" onClick={() => { this.props.edit(trData["id"]); this.toggleTooltip("") }} ></Button>
                                    </div>
                                    :
                                    this.props.edit && this.props.checkEditable && !trData["editable"] && ownSchool &&
                                    <div className="td-div">
                                        <Label className="d-none" for={"TooltipEditInfo" + trIndex}><FormattedMessage id="tablebody.label.editinfo" /></Label>
                                        <Button color="link" id={"TooltipEditInfo" + trIndex} name={"TooltipEditInfo" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipEditInfo" + trIndex)} className="p-0 m-0 editinfo" onClick={() => { this.props.edit(trData["id"]); this.toggleTooltip("") }} ></Button>
                                    </div>
                            }
                            { //Info
                                this.props.info && ownSchool &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipInfo" + trIndex}><FormattedMessage id="tablebody.label.info" /></Label>
                                    <Button color="link" id={"TooltipInfo" + trIndex} name={"TooltipInfo" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipInfo" + trIndex)} className="p-0 m-0 info" onClick={() => { this.props.info(trData["id"]); this.toggleTooltip("") }} ></Button>
                                </div>
                            }
                            { //inquiry
                                this.props.inquiry && trData["editable"] && ownSchool &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipInquiry" + trIndex}><FormattedMessage id="tablebody.label.inquiry" /></Label>
                                    <Button color="link" id={"TooltipInquiry" + trIndex} name={"TooltipInquiry" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipInquiry" + trIndex)} className="p-0 m-0 inquiry" onClick={() => { this.props.inquiry(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //Delete
                                (this.props.delete && trData["deletable"]) && ownSchool &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipDelete" + trIndex}><FormattedMessage id="tablebody.label.delete" /></Label>
                                    <Button color="link" id={"TooltipDelete" + trIndex} name={"TooltipDelete" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipDelete" + trIndex)} className="text-danger p-0 m-0 delete" onClick={() => { this.props.delete(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //Reset
                                (this.props.reset && !trData["deletable"] && trData["resettable"]) && ownSchool &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipDelete" + trIndex}><FormattedMessage id="tablebody.label.delete" /></Label>
                                    <Button color="link" id={"TooltipReset" + trIndex} name={"TooltipReset" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipReset" + trIndex)} className="text-danger p-0 m-0 delete" onClick={() => { this.props.reset(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //ResetPwd
                                (this.props.resetPwd) &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipResetPwd" + trIndex}><FormattedMessage id="tablebody.label.resetpwd" /></Label>
                                    <Button color="link" id={"TooltipResetPwd" + trIndex} name={"TooltipResetPwd" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipResetPwd" + trIndex)} className="text-danger p-0 m-0 resetpwd" onClick={() => { this.props.resetPwd(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //ResetPwd PDF
                                (this.props.resetPwdPdf) &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipResetPwdPdf" + trIndex}><FormattedMessage id="tablebody.label.resetpwdpdf" /></Label>
                                    <Button color="link" id={"TooltipResetPwdPdf" + trIndex} name={"TooltipResetPwdPdf" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipResetPwdPdf" + trIndex)} className="text-danger p-0 m-0 resetpwdpdf" onClick={() => { this.props.resetPwdPdf(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //ResetPwd EMail single user (not for students)
                                (this.props.resetPwdUserEmail && trData["email"] && trData["email"] !== '' && trData["roles"].filter(r => r.id !== authorizationRoles.student).length > 0
                                    && this.props.auth.roles.filter(r => r === authorizationRoles.pm).length > 0)
                                &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipResetPwdEMail" + trIndex}><FormattedMessage id="tablebody.label.resetpwdemail" /></Label>
                                    <Button color="link" id={"TooltipResetPwdEMail" + trIndex} name={"TooltipResetPwdEMail" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipResetPwdEMail" + trIndex)} className="text-danger p-0 m-0 resetpwd" onClick={() => { this.props.resetPwdUserEmail(trData["username"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //ResetPwd PDF single user (not for students)
                                (((this.props.resetPwdUserPdf && trData["deletable"]) && ownSchool && trData["roles"].filter(r => r.id !== authorizationRoles.student).length > 0)
                                    || (this.props.resetPwdUserPdf && this.props.auth.roles.filter(r => r === authorizationRoles.pm).length > 0
                                        && (trData["roles"].filter(r => r.id === authorizationRoles.student).length > 0 ? trData['hasPassword'] || trData['isInExecution'] : true))
                                    || (this.props.resetPwdUserPdf && this.props.auth.roles.filter(r => r === authorizationRoles.school).length > 0
                                        && (trData["roles"].filter(r => r.id === authorizationRoles.student).length > 0 ? trData['hasPassword'] || trData['isInExecution'] : false)))
                                &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipResetPwdUserPdf" + trIndex}><FormattedMessage id="tablebody.label.resetpwdpdf" /></Label>
                                    <Button color="link" id={"TooltipResetPwdUserPdf" + trIndex} name={"TooltipResetPwdUserPdf" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipResetPwdUserPdf" + trIndex)} className="text-danger p-0 m-0 resetpwdpdf" onClick={() => { this.props.resetPwdUserPdf(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //Create pdf with foreignusers
                                (this.props.foreignUsersPdf && !ownSchool) &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipForeignUsersPdf" + trIndex}><FormattedMessage id="tablebody.label.foreignuserspdf" /></Label>
                                    <Button color="link" id={"TooltipForeignUsersPdf" + trIndex} name={"TooltipForeignUsersPdf" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipForeignUsersPdf" + trIndex)} className="text-danger p-0 m-0 foreignuserspdf" onClick={() => { this.props.foreignUsersPdf(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //Print Kompo7
                                (this.props.print && (!trData.hasOwnProperty('skillsProfile') || (trData.skillsProfile && trData.skillsProfile.approved === 1))) &&

                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipPrint" + trIndex}><FormattedMessage id="tablebody.label.print" /></Label>
                                    <Button color="link" id={"TooltipPrint" + trIndex} name={"TooltipPrint" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipPrint" + trIndex)} className="text-danger p-0 m-0 print" onClick={() => { this.props.print(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //Print KompoG
                                (this.props.printG && (
                                    (trData.hasOwnProperty('results_completed') && trData['results_completed'] === true)
                                    || (trData.hasOwnProperty('interests_completed') && trData['interests_completed'] === true)
                                    || (trData.hasOwnProperty('motivations_completed') && trData['motivations_completed'] === true)
                                    || (trData.hasOwnProperty('impulses_completed') && trData['impulses_completed'] === true)
                                    || (trData.hasOwnProperty('releaseTeacher') && trData['releaseTeacher'] === 1))
                                ) &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipPrintG" + trIndex}><FormattedMessage id="tablebody.label.print" /></Label>
                                    <Button color="link" id={"TooltipPrintG" + trIndex} name={"TooltipPrintG" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipPrintG" + trIndex)} className="text-danger p-0 m-0 print" onClick={() => { this.props.printG(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //release feedback
                                // (this.props.release && (!trData.hasOwnProperty('releaseTeacher') || (trData.releaseTeacher && trData.releaseTeacher === 0))) &&
                                this.props.release && trData.hasOwnProperty('releaseTeacher') && trData.releaseTeacher === 0 &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipRelease" + trIndex}><FormattedMessage id="tablebody.label.release" /></Label>
                                    <Button color="link" id={"TooltipRelease" + trIndex} name={"TooltipRelease" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipRelease" + trIndex)} className="text-danger p-0 m-0 release" onClick={() => { this.props.release(trData["id"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //Reset SkillsProfile
                                (this.props.resetSkillsProfile && (!trData.hasOwnProperty('skillsProfile') || (trData.skillsProfile && trData.skillsProfile.approved === 1))) &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipResetSkillsProfile" + trIndex}><FormattedMessage id="tablebody.label.resetskillsprofile" /></Label>
                                    <Button color="link" id={"TooltipResetSkillsProfile" + trIndex} name={"TooltipResetSkillsProfile" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipResetSkillsProfile" + trIndex)} className="text-danger p-0 m-0 reset" onClick={() => { this.props.resetSkillsProfile(trData["skillsProfile"]); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            { //Reset KompoG Profile
                                this.props.resetProfile && trData.hasOwnProperty('releaseTeacher') && trData.releaseTeacher === 1 &&
                                <div className="td-div">
                                    <Label className="d-none" for={"TooltipResetProfile" + trIndex}><FormattedMessage id="tablebody.label.resetskillsprofile" /></Label>
                                    <Button color="link" id={"TooltipResetProfile" + trIndex} name={"TooltipResetProfile" + trIndex} onMouseOver={this.toggleTooltip.bind(this, "TooltipResetProfile" + trIndex)} className="text-danger p-0 m-0 reset" onClick={() => { this.props.resetProfile(trData); this.toggleTooltip("") }}></Button>
                                </div>
                            }
                            {
                                //Tooltips inquiry
                                this.props.inquiry && trData["editable"] && ownSchool &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipInquiry" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipInquiry" + trIndex}><FormattedMessage id="tablebody.tooltip.inquiry" /></Tooltip>
                            }
                            {
                                //Tooltips Group
                                this.props.group && ownSchool &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipGroup" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipGroup" + trIndex}><FormattedMessage id={(CheckAuthorization("executionsgroupmodal-edit", this.props.auth.roles) ? "tablebody.tooltip.groupedit" : "tablebody.tooltip.group") + (procedure !== 1 ? ".kompoG" : "")} /></Tooltip>
                            }
                            {
                                //Tooltips Edit
                                this.props.edit && (!this.props.checkEditable || (this.props.checkEditable && trData["editable"])) && ownSchool ?
                                    <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipEdit" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipEdit" + trIndex} ><FormattedMessage id="tablebody.tooltip.edit" /></Tooltip>
                                    :
                                    this.props.edit && this.props.checkEditable && !trData["editable"] && ownSchool &&
                                    <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipEditInfo" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipEditInfo" + trIndex} ><FormattedMessage id="tablebody.tooltip.editinfo" /></Tooltip>

                            }
                            {
                                //Tooltips Info
                                this.props.info && !trData["editable"] && ownSchool &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipInfo" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipInfo" + trIndex} ><FormattedMessage id="tablebody.tooltip.info" /></Tooltip>

                            }
                            {
                                //Tooltips Delete
                                (this.props.delete && trData["deletable"]) && ownSchool &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipDelete" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipDelete" + trIndex}><FormattedMessage id="tablebody.tooltip.delete" /></Tooltip >
                            }
                            {
                                //Tooltips Reset
                                (this.props.reset && !trData["deletable"] && trData["resettable"]) && ownSchool &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipReset" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipReset" + trIndex}><FormattedMessage id="tablebody.tooltip.delete" /></Tooltip >
                            }
                            {
                                //Reset pwd
                                (this.props.resetPwd) &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipResetPwd" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipResetPwd" + trIndex}><FormattedMessage id="tablebody.tooltip.resetpwd" /></Tooltip >
                            }
                            {
                                //Reset pwd PDF
                                (this.props.resetPwdPdf) &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipResetPwdPdf" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipResetPwdPdf" + trIndex}><FormattedMessage id="tablebody.tooltip.resetpwdpdf" /></Tooltip >
                            }
                            {
                                (this.props.resetPwdUserEmail && trData["email"] && trData["email"] !== '' && trData["roles"].filter(r => r.id !== authorizationRoles.student).length > 0
                                    && this.props.auth.roles.filter(r => r === authorizationRoles.pm).length > 0)
                                &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipResetPwdEMail" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipResetPwdEMail" + trIndex}><FormattedMessage id="tablebody.tooltip.resetpwdemail" /></Tooltip >
                            }
                            {
                                //Reset pwd PDF single user (not for students)
                                (((this.props.resetPwdUserPdf && trData["deletable"]) && ownSchool && trData["roles"].filter(r => r.id !== authorizationRoles.student).length > 0)
                                    || (this.props.resetPwdUserPdf && this.props.auth.roles.filter(r => r === authorizationRoles.pm).length > 0
                                        && (trData["roles"].filter(r => r.id === authorizationRoles.student).length > 0 ? trData['hasPassword'] || trData['isInExecution'] : true))
                                    || (this.props.resetPwdUserPdf && this.props.auth.roles.filter(r => r === authorizationRoles.school).length > 0
                                        && (trData["roles"].filter(r => r.id === authorizationRoles.student).length > 0 ? trData['hasPassword'] || trData['isInExecution'] : false)))
                                &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipResetPwdUserPdf" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipResetPwdUserPdf" + trIndex}><FormattedMessage id="tablebody.tooltip.resetpwduserpdf" /></Tooltip >
                            }
                            {
                                //Print
                                (this.props.print && (!trData.hasOwnProperty('skillsProfile') || (trData.skillsProfile && trData.skillsProfile.approved === 1))) &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipPrint" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipPrint" + trIndex}><FormattedMessage id="tablebody.tooltip.print" /></Tooltip >
                            }
                            {
                                //Print KompoG
                                (this.props.printG && (
                                    (trData.hasOwnProperty('results_completed') && trData['results_completed'] === true)
                                    || (trData.hasOwnProperty('interests_completed') && trData['interests_completed'] === true)
                                    || (trData.hasOwnProperty('motivations_completed') && trData['motivations_completed'] === true)
                                    || (trData.hasOwnProperty('impulses_completed') && trData['impulses_completed'] === true)
                                    || (trData.hasOwnProperty('releaseTeacher') && trData['releaseTeacher'] === 1))
                                ) &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipPrintG" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipPrintG" + trIndex}><FormattedMessage id="tablebody.tooltip.print" /></Tooltip >

                            }
                            {
                                //Release
                                this.props.release && trData.hasOwnProperty('releaseTeacher') && trData.releaseTeacher === 0 &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipRelease" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipRelease" + trIndex}><FormattedMessage id="tablebody.tooltip.release" /></Tooltip >
                            }
                            {
                                //Tooltip Reset SkillsProfile
                                (this.props.resetSkillsProfile && (!trData.hasOwnProperty('skillsProfile') || (trData.skillsProfile && trData.skillsProfile.approved === 1))) &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipResetSkillsProfile" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipResetSkillsProfile" + trIndex}><FormattedMessage id="tablebody.tooltip.resetskillsprofile" /></Tooltip >
                            }
                            {
                                //Tooltip Reset KompG Profil
                                this.props.resetProfile && trData.hasOwnProperty('releaseTeacher') && trData.releaseTeacher === 1 &&
                                <Tooltip toggle={this.toggleTooltip.bind(this, "")} isOpen={this.state.tooltipOpen === ("TooltipResetProfile" + trIndex)} placement="auto" delay={config.toolTipDelay} target={"TooltipResetProfile" + trIndex}><FormattedMessage id="tablebody.tooltip.resetskillsprofile" /></Tooltip >
                            }
                        </div>
                    </td>
                }
            </tr>
        })
        }
        </tbody>;
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(TableBody));