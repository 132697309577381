import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Alert, Row, Col, Table, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SchoolSelect from '../components/SchoolSelect';
import RolesSelect from '../components/RolesSelect';
import CertifiedSelect from '../components/CertifiedSelect';
import ExecutionSelect from '../components/ExecutionSelect';
import DepartmentSelect from '../components/DepartmentSelect';
import SchooltypeSelect from '../components/SchooltypeSelect';
import ProcedureSelect from '../components/ProcedureSelect';
import TableHead from '../components/TableHead';
import TableBody from '../components/TableBody';
import Authorization from '../components/Authorization';
import Confirm from '../components/Confirm';
import Pages from '../components/Pages';
import { schoolsActions, departmentsActions, userProfileActions, filterActions, loginActions } from '../actions';
import { authorizationRoles } from '../config/authorization'
import config from '../config/config';
import UserAdministrationModal from '../components/UserAdministrationModal';
import ImportUserModal from '../components/ImportUserModal';
import UploadFile from '../components/UploadFile';
import { CheckAuthorization } from '../models/Authorization';
import { generateResetPwdUsersPDF } from '../pdf';

class UserAdministrationPage extends Component {

    static propTypes = {

    };

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            totalpages: 1,
            pages: 5,
            pagelimit: 10,
            loading: false,
            confirm: false,
            confirmResetPwd: false,
            confirmReset: false,
            deleteId: -1,
            resetId: -1,
            search: "",
            order: "firstname",
            th: [
                { icon: { className: "icon-certified", src: "images/BWHW_Logo_Stempel.png", alt: "tablehead.alt.certified" }, width: 2, role: [authorizationRoles.teacher, authorizationRoles.pm, authorizationRoles.hk, authorizationRoles.school], /*userRole: authorizationRoles.pm*/ },
                { text: "useradministration.th.firstname", order: "firstname" },
                { text: "useradministration.th.lastname", order: "lastname" },
                { text: "useradministration.th.email", order: "email" },
                { text: "useradministration.th.usersfunctions", order: "usersfunctions", width: 10, role: [authorizationRoles.teacher, authorizationRoles.school] },
                { text: "useradministration.th.class", order: "class", width: 10, role: [authorizationRoles.student, authorizationRoles.teacher], userRole: authorizationRoles.school },
                { text: "useradministration.th.school", order: "school", role: [authorizationRoles.student, authorizationRoles.teacher], hidebySchoolSelection: true },
                { text: "useradministration.th.role", order: "role", role: [authorizationRoles.school, authorizationRoles.teacher] },
                { text: "useradministration.th.action", className: "table-border-left-action", width: 10 },
            ],
            tb: [],
            tbshow: [
                { value: "certified", role: [authorizationRoles.teacher, authorizationRoles.pm, authorizationRoles.hk, authorizationRoles.school], /*userRole: authorizationRoles.pm*/ },
                { value: "firstname" },
                { value: "lastname" },
                { value: "email" },
                { value: "functions", role: [authorizationRoles.teacher, authorizationRoles.school] },
                { value: "class", role: [authorizationRoles.student, authorizationRoles.teacher], userRole: authorizationRoles.school },
                { value: "school", role: [authorizationRoles.student, authorizationRoles.teacher], hidebySchoolSelection: true },
                { value: "roles", role: [authorizationRoles.school, authorizationRoles.teacher] }],
            openModalImport: false,
            showImportUploadError: false,
            importUploadError: null,
            alert: { visible: false, textId: "alert.save", color: "success" },
            openModalResetPwdError: false,
            confirmPwdResetTeacherPdf: false,
            confirmPwdResetEMail: false,
            resetPwdTeacherIdPdf: -1,
            resetPwdIdEmail: "",
            showGlobalLoading: false,
            searchTimer: null,
            procedures: []
        }

    }

    //return tablehead of the selected role and 
    //calculate the width of each th with no width
    //removed also school is school selected
    getTableHead() {
        let tmpTh = [];
        let width = 0;
        let countwidth = 0;
        this.state.th.forEach((th) => {

            // userRole not exists or the user has NOT the role
            if (!th.userRole || this.props.auth.roles.indexOf(th.userRole) === -1) {
                //if hidebySchoolSelction nots exists or no school is selected 
                if (!th.hidebySchoolSelection || (th.hidebySchoolSelection && this.props.schoolId === -1)) {
                    if (this.props.roleId === -1 || (th.role && th.role.indexOf(this.props.roleId) !== -1)) {
                        tmpTh.push(JSON.parse(JSON.stringify(th)));
                        if (th.width) {
                            width += th.width;
                            countwidth++;
                        }
                    } else if (!th.role) {
                        tmpTh.push(JSON.parse(JSON.stringify(th)));
                        if (th.width) {
                            width += th.width;
                            countwidth++;
                        }
                    }
                }
            }

        });


        let newwidth = (100 - width) / (tmpTh.length - countwidth);
        tmpTh.forEach((th) => {
            if (th.width) {
                th.width = th.width + '%';
            } else {
                th.width = newwidth + '%'
            }
        });
        return tmpTh;
    }

    //return tableshow of the selected role
    //removed also school is school selected
    getTableShow() {
        let tmpShow = [];
        this.state.tbshow.forEach((ts) => {
            // userRole not exists or the user has NOT the role
            if (!ts.userRole || this.props.auth.roles.indexOf(ts.userRole) === -1) {
                //if hidebySchoolSelction nots exists or no school is selected 
                if (!ts.hidebySchoolSelection || (ts.hidebySchoolSelection && this.props.schoolId === -1)) {
                    if (this.props.roleId === -1 || (ts.role && ts.role.indexOf(this.props.roleId) !== -1)) {
                        tmpShow.push(ts.value ? ts.value : '');
                    } else if (!ts.role) {
                        tmpShow.push(ts.value ? ts.value : '');
                    }
                }
            }
        });
        return tmpShow;
    }

    componentDidMount() {
        this.props.dispatchGetSchoolsForUser(this.props.auth.token);
        this.props.dispatchGetRoles(this.props.auth.token);
        this.props.dispatchGetDepartments(this.props.auth.token);
        this.props.dispatchGetSchooltypes(this.props.auth.token);
        this.getData();
    }

    addProcedureToHeader() {
        this.setState({
            th: [
                { icon: { className: "icon-certified", src: "images/BWHW_Logo_Stempel.png", alt: "tablehead.alt.certified" }, width: 2, role: [authorizationRoles.teacher, authorizationRoles.pm, authorizationRoles.hk, authorizationRoles.school], /*userRole: authorizationRoles.pm*/ },
                { text: "useradministration.th.firstname", order: "firstname" },
                { text: "useradministration.th.lastname", order: "lastname" },
                { text: "useradministration.th.email", order: "email" },
                { text: "useradministration.th.usersfunctions", order: "usersfunctions", width: 10, role: [authorizationRoles.teacher, authorizationRoles.school] },
                { text: "useradministration.th.class", order: "class", width: 10, role: [authorizationRoles.student, authorizationRoles.teacher], userRole: authorizationRoles.school },
                { text: "useradministration.th.school", order: "school", role: [authorizationRoles.student, authorizationRoles.teacher], hidebySchoolSelection: true },
                { text: "useradministration.th.role", order: "role", role: [authorizationRoles.school, authorizationRoles.teacher] },
                { text: "useradministration.th.procedure", order: "procedureString", width: 12 },
                { text: "useradministration.th.action", className: "table-border-left-action", width: 10 },
            ]
        });
        this.setState({
            tbshow: [
                { value: "certified", role: [authorizationRoles.teacher, authorizationRoles.pm, authorizationRoles.hk, authorizationRoles.school], /*userRole: authorizationRoles.pm*/ },
                { value: "firstname" },
                { value: "lastname" },
                { value: "email" },
                { value: "functions", role: [authorizationRoles.teacher, authorizationRoles.school] },
                { value: "class", role: [authorizationRoles.student, authorizationRoles.teacher], userRole: authorizationRoles.school },
                { value: "school", role: [authorizationRoles.student, authorizationRoles.teacher], hidebySchoolSelection: true },
                { value: "roles", role: [authorizationRoles.school, authorizationRoles.teacher] },
                { value: "procedureString" }]
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.schools !== this.props.schools && this.props.schools.length > 0) {
            this.props.schools.forEach(school => {
                school.procedures.forEach(p => {
                    if (!this.state.procedures.some(item => item.id === p.id)) {
                        let array = this.state.procedures;
                        array.push(p);
                        this.setState({ procedures: array });
                        if (array.length > 1) {
                            this.addProcedureToHeader();
                        }
                    }
                });
            });
        }

        if (prevState.procedures !== this.state.procedures && this.state.procedures.length > 1) {
            this.addProcedureToHeader();
        }

        if (prevProps.schoolId !== this.props.schoolId || prevProps.roleId !== this.props.roleId || prevProps.departmentId !== this.props.departmentId || prevProps.schooltypeId !== this.props.schooltypeId || prevProps.executionId !== this.props.executionId || prevProps.certified !== this.props.certified || prevProps.procedureId !== this.props.procedureId) {
            this.getData();
        }

        if (prevProps.total !== this.props.total) {
            //Check if the page still exists
            if (this.state.page > Math.ceil(this.props.total / this.state.pagelimit)) {
                let newPage = Math.ceil(this.props.total / this.state.pagelimit);
                this.setState({ page: newPage <= 0 ? 1 : newPage });
            }
        }

        /* alert delete */
        if (prevProps.deleteUserState !== this.props.deleteUserState) {
            if (this.props.deleteUserState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.delete", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.deleteUserState === 2) {
                this.setState({ alert: { visible: true, textId: "alert.delete-error", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* alert create and update */
        if (prevProps.createUserState !== this.props.createUserState || prevProps.updateUserState !== this.props.updateUserState) {
            if (this.props.createUserState === 1 || this.props.updateUserState === 1) {
                this.setState({ openModalNewEdit: false, alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                this.getData();
            } else if (this.props.createUserState === 2 || this.props.updateUserState === 2) {
                if (this.props.createError === 'email' || this.props.updateError === 'email') {
                    this.setState({ alert: { visible: true, textId: "useradministration.alert.email", color: "danger" } });
                } else if (this.props.createError === 'Email could not be sent!') {
                    this.setState({ alert: { visible: true, textId: "useradministration.alert.email.not.send", color: "danger" } });
                } else {
                    this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                }
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* reset pwd */
        if (prevProps.resetPwdState !== this.props.resetPwdState) {
            if (this.props.resetPwdState === 1) {
                if (this.props.resetPwdErrors.length === 0) {
                    this.setState({ alert: { visible: true, textId: "useradministration.alert.resetpwd.success", color: "success" } });
                    setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                } else {
                    this.setState({ openModalResetPwdError: true });
                }

            } else if (this.props.resetPwdState === 2) {
                this.setState({ alert: { visible: true, textId: "useradministration.alert.resetpwd.failure", color: "danger" } });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        /* reset pwd teacher pdf */
        if (prevProps.resetPwdUserPdfState !== this.props.resetPwdUserPdfState) {
            if (this.props.resetPwdUserPdfState === 1) {
                if (this.props.resetPwdUserPdfUserdata !== null) {
                    // await this.setState();

                    generateResetPwdUsersPDF(this.props, this.state, [this.props.resetPwdUserPdfUserdata]);

                    // hide the loader if the pdf was created
                    this.setState({ showGlobalLoading: false });
                } else {
                    this.setState({ alert: { visible: true, textId: "useradministration.alert.resetpwdteacherpdf.failure", color: "danger" }, showGlobalLoading: false });
                    setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
                }

            } else if (this.props.resetPwdUserPdfState === 2) {
                this.setState({ alert: { visible: true, textId: "useradministration.alert.resetpwdteacherpdf.failure", color: "danger" }, showGlobalLoading: false });
                setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
            }
        }

        if (this.props.sendResetPwdMailError && prevProps.sendResetPwdMailError !== this.props.sendResetPwdMailError) {
            this.setState({ alert: { visible: true, textId: "loginpage.alert.resetmail-error", color: "danger" }, showGlobalLoading: false });
            setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
        }
        else if (prevProps.sendResetPwdMailSuccess !== this.props.sendResetPwdMailSuccess &&
            !this.props.sendResetPwdMailError && this.props.sendResetPwdMailSuccess) {
            this.setState({ alert: { visible: true, textId: "loginpage.alert.resetmail-success", color: "success", showGlobalLoading: false } });
            setTimeout(this.hideAlert.bind(this), config.alertTimeOut);
        }
    }

    getData() {
        this.props.dispatchGetUsers(
            this.props.auth.token,
            this.state.order,
            this.state.desc,
            this.state.search,
            this.state.page,
            this.state.pagelimit,
            {
                departmentId: this.props.departmentId,
                schooltypeId: this.props.schooltypeId,
                schoolId: this.props.schoolId,
                roleId: this.props.roleId,
                executionId: this.props.executionId,
                certified: this.props.certified,
                procedureId: this.props.procedureId
            });
    }

    /* CLICK EDIT */
    handleEdit = (id) => {
        this.handleOpenModalNewEdit(id)
    }

    handleOpenModalNewEdit = (id, e) => {

        let index = this.props.users.map((value) => { return value.id }).indexOf(id);
        if (id === -1 || index === -1) {
            this.setState({ editData: null })
        } else {
            this.setState({ editData: this.props.users[index] });
        }
        this.setState({
            openModalNewEdit: !this.state.openModalNewEdit
        });
    }

    /* CLICK DELETE */
    handleDelete = (id) => {
        this.setState({ deleteId: id });
        this.toggleConfirm();
    }

    handleReset = (id) => {
        this.setState({ resetId: id });
        this.toggleConfirmReset();
    }

    toggleConfirmReset = (e) => {
        this.setState({
            confirmReset: !this.state.confirmReset
        });
    }

    reset = (e) => {
        if (this.state.resetId) {
            this.props.dispatchResetUser(this.props.auth.token, this.state.resetId);
        }
        this.toggleConfirmReset();
    }

    /* CLICK RESET PWD */
    handleConfirmPwdReset = () => {
        this.toggleConfirmResetPwd();
    };

    /* RESET PWD ERRORS OCCURED */
    handleShowResetPwdError = () => {
        this.toggleModalResetPwdError();
    };

    /* CLICK RESET PWD FOR SINGLE TEACHER */
    handleConfirmPwdResetTeacherPdf = (teacherId) => {
        this.toggleConfirmPwdResetTeacherPdf(teacherId);
    };

    /* CLICK RESET PWD FOR SINGLE USER */
    handleConfirmPwdResetEMail = (id) => {
        this.toggleConfirmPwdResetEmail(id);
    };

    handleOnUploadSuccess = (response) => {
        this.setState({ openModalImport: true, importFileData: response });
    }

    handleOnUploadFailure = (err) => {
        this.setState({ showImportUploadError: true, importUploadError: err });
    }

    handleToggleModalUploadError = (e) => {
        this.setState({
            showImportUploadError: !this.state.showImportUploadError
        });
    }

    handleCloseUploadErrorModal = (e) => {
        this.handleToggleModalUploadError();
    }

    handleOpenModalImport = (e) => {
        // refresh the users list if the import window was closed
        if (!this.state.openModalImport) {
            this.getData();
        }

        this.setState({
            openModalImport: !this.state.openModalImport
        });
    }

    /* Hide Alert */
    hideAlert() {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    }

    order = (order, desc, e) => {
        this.setState({ order: order, desc: desc });
        this.props.dispatchGetUsers(
            this.props.auth.token,
            order,
            desc,
            this.state.search,
            this.state.page,
            this.state.pagelimit,
            {
                departmentId: this.props.departmentId,
                schooltypeId: this.props.schooltypeId,
                schoolId: this.props.schoolId,
                roleId: this.props.roleId,
                executionId: this.props.executionId,
                certified: this.props.certified,
                procedureId: this.props.procedureId
            });
    }

    handlePage = (page) => {
        this.setState({ page: page });
        this.props.dispatchGetUsers(
            this.props.auth.token,
            this.state.order,
            this.state.desc,
            this.state.search,
            page,
            this.state.pagelimit,
            {
                departmentId: this.props.departmentId,
                schooltypeId: this.props.schooltypeId,
                schoolId: this.props.schoolId,
                roleId: this.props.roleId,
                executionId: this.props.executionId,
                certified: this.props.certified,
                procedureId: this.prop
            }
        )
    }

    search = (e) => {
        let search = e.target.value
        let page = 1
        this.setState({ search: search, page: page });

        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
            this.searchTimer = null;
        }
        //start search after 500ms
        this.searchTimer = setTimeout(() => {
            this.props.dispatchGetUsers(
                this.props.auth.token,
                this.state.order,
                this.state.desc,
                search,
                page,
                this.state.pagelimit,
                {
                    departmentId: this.props.departmentId,
                    schooltypeId: this.props.schooltypeId,
                    schoolId: this.props.schoolId,
                    roleId: this.props.roleId,
                    executionId: this.props.executionId,
                    certified: this.props.certified,
                    procedureId: this.prop
                })
        }, 500);

    }

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    }

    toggleConfirmResetPwd = (e) => {
        this.setState({
            confirmResetPwd: !this.state.confirmResetPwd
        });
    };

    toggleModalResetPwdError = (e) => {
        this.setState({
            openModalResetPwdError: !this.state.openModalResetPwdError
        });
    };

    toggleConfirmPwdResetTeacherPdf = (teacherId) => {
        this.setState({
            confirmPwdResetTeacherPdf: !this.state.confirmPwdResetTeacherPdf,
            resetPwdTeacherIdPdf: teacherId,
        });
    };

    toggleConfirmPwdResetEmail = (id) => {
        this.setState({
            confirmPwdResetEMail: !this.state.confirmPwdResetEMail,
            resetPwdIdEmail: id,
        });
    };

    delete = (e) => {
        if (this.state.deleteId) {
            this.props.dispatchDeleteUser(this.props.auth.token, this.state.deleteId);
        }
        this.toggleConfirm();
    }

    save(user) {
        if (!user.id) {
            this.props.dispatchCreateUser(this.props.auth.token, user);
        } else {
            this.props.dispatchUpdateUser(this.props.auth.token, user);
        }
    }

    resetPwdOfTeachers = (e) => {
        this.props.dispatchResetPwd(this.props.auth.token, { role: authorizationRoles.teacher, school: this.props.schoolId });

        this.toggleConfirmResetPwd();
    };

    resetPwdEMail = (e) => {
        if (this.state.resetPwdIdEmail !== "") {
            this.props.dispatchSendResetPwdMail(this.state.resetPwdIdEmail);
            this.setState({ showGlobalLoading: true });
        }

        this.toggleConfirmPwdResetEmail("");
    };

    resetPwdOfTeacherPdf = () => {
        if (this.state.resetPwdTeacherIdPdf > -1) {
            this.props.dispatchResetPwdTeacherPdf(this.props.auth.token, this.state.resetPwdTeacherIdPdf);
            this.setState({ showGlobalLoading: true });
        }

        this.toggleConfirmPwdResetTeacherPdf(-1);
    };

    render() {
        return (
            <Container fluid role="main" className="content-margin">

                {/* Alert update and save */}
                <Alert isOpen={this.state.alert.visible} toggle={this.hideAlert.bind(this)} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                {/* Confirm delete */}
                <Confirm open={this.state.confirm} toggle={this.toggleConfirm} no={this.toggleConfirm} yes={this.delete} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                {/* Confirm reset pwd */}
                <Confirm open={this.state.confirmResetPwd} toggle={this.toggleConfirmResetPwd} no={this.toggleConfirmResetPwd} yes={this.resetPwdOfTeachers} headertext="useradministration.confirm.header.resetpwd" bodytext="useradministration.confirm.body.resetpwd.teacher" />

                {/* Confirm reset pwd email */}
                <Confirm open={this.state.confirmPwdResetEMail} toggle={this.toggleConfirmPwdResetEmail} no={this.toggleConfirmPwdResetEmail} yes={this.resetPwdEMail} headertext="useradministration.confirm.header.resetpwd" bodytext="useradministration.confirm.body.resetpwdemail" />

                {/* Confirm reset pwd pdf */}
                <Confirm open={this.state.confirmPwdResetTeacherPdf} toggle={this.toggleConfirmPwdResetTeacherPdf} no={this.toggleConfirmPwdResetTeacherPdf} yes={this.resetPwdOfTeacherPdf} headertext="useradministration.confirm.header.resetpwdteacherpdf" bodytext="useradministration.confirm.body.resetpwdteacherpdf.teacher" />

                {/* Confirm reset */}
                <Confirm open={this.state.confirmReset} toggle={this.toggleConfirmReset} no={this.toggleConfirmReset} yes={this.reset} headertext="confirm.header.delete" bodytext="confirm.body.delete" />

                <Modal isOpen={this.state.openModalResetPwdError} toggle={this.handleShowResetPwdError} size="lg" backdrop={'static'}>
                    <ModalHeader toggle={this.handleShowResetPwdError}><FormattedMessage id="useradministration.modal.title.resetpwderror" /></ModalHeader>
                    <ModalBody>
                        <FormattedMessage id="useradministration.modal.body.resetpwderror" />
                        <br /><br />
                        {this.props.resetPwdErrors.map(err => {
                            return (
                                <Row>
                                    <Col>{err.email}</Col>
                                </Row>
                            );
                        })}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.handleShowResetPwdError} name="close"><FormattedMessage id="button.close" /></Button>
                    </ModalFooter>
                </Modal>

                <h1><FormattedMessage id="useradministration.title.useradministration" /></h1>

                {/* Search */}
                <Container fluid className="m-0 p-0 mb-3">
                    <Row>
                        <Col xs="12" md="6" lg="6" xl="4">
                            <Input type="text" id="search" bsSize="sm" name="search" placeholder={this.props.intl.formatMessage({ id: "execution.search.placeholder" })} autoComplete="off" onKeyUp={this.search} />
                        </Col>
                    </Row>
                </Container>

                {/* Buttons */}
                <Row>
                    <Col xs="12" md="6" lg="6" xl="4" className="mb-3">
                        <Button color="primary" block onClick={this.handleOpenModalNewEdit.bind(this, -1)} id="newUser"><FormattedMessage id="useradministration.button.new-user" /></Button>
                    </Col>
                    <Authorization id="useradministration-import-user">
                        <Col xs="12" md="6" lg="6" xl="4" className="mb-3">
                            <UploadFile className="p-0" uploadUrl="/users/import/init" onUploadSuccess={this.handleOnUploadSuccess.bind(this)} onUploadFailure={this.handleOnUploadFailure.bind(this)} allowedFileExts="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" buttonText={this.props.intl.formatMessage({ id: 'useradministration.button.importuser' })} />
                        </Col>
                    </Authorization>
                    <Authorization id="useradministration-reset-pwd-all-teacher">
                        <Col xs="12" md="6" lg="6" xl="4" className="mb-3">
                            <Button color="primary" block onClick={this.handleConfirmPwdReset.bind(this)} id="resetPwd"><FormattedMessage id="useradministration.button.reset-pwd" /></Button>
                        </Col>
                    </Authorization>
                </Row>

                <Row className="mb-2">
                    { /* Select Role */}
                    <Col xs="12" md="6" lg="6" xl="4">
                        <RolesSelect withEmpty className="mb-3 p-0" label="useradministration.label.role" roles={this.props.roles} handleRole={this.props.dispatchRole.bind(this)} roleId={this.props.roleId} />
                    </Col>
                    { /* Select Department */}
                    <Authorization id="useradministration-filter-department">
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <DepartmentSelect className={this.props.departments.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="useradministration.label.department" departments={this.props.departments} handleDepartment={this.props.dispatchDepartment.bind(this)} departmentId={this.props.departmentId} />
                        </Col>
                    </Authorization>
                    { /* Select Schooltype */}
                    <Authorization id="useradministration-filter-schooltype">
                        <Col xs="12" sm="6" md="6" lg="6" xl="4">
                            <SchooltypeSelect className={this.props.schooltypes.length > 1 ? "mb-3 p-0" : "d-none"} withEmpty label="useradministration.label.schooltype" schooltypes={this.props.schooltypes} handleSchooltype={this.props.dispatchSchooltype.bind(this)} schooltypeId={this.props.schooltypeId} />
                        </Col>
                    </Authorization>
                    { /* Select School */}
                    <Authorization id="useradministration-filter-school">
                        <Col xs="12" md="6" lg="6" xl="4">
                            <SchoolSelect withEmpty className="mb-3 p-0" label="useradministration.label.school" schools={this.props.schools} handleSchool={this.props.dispatchSchool.bind(this)} schoolId={this.props.schoolId} />
                        </Col>
                    </Authorization>
                    { /* Select Execution */}
                    <Col xs="12" md="6" lg="6" xl="4">
                        <ExecutionSelect className="mb-3 p-0" withEmpty label="useradministration.label.execution" executionId={this.props.executionsId} schoolId={this.props.schoolId} past={true} />
                    </Col>
                    { /* Select Certified */}
                    <Authorization id="useradministration-filter-certified">
                        <Col xs="12" md="6" lg="6" xl="4">
                            <CertifiedSelect withEmpty className="mb-3 p-0" label="useradministration.label.certified" handleCertified={this.props.dispatchCertified.bind(this)} certified={this.props.certified} />
                        </Col>
                    </Authorization>
                    {/* Select Procedure */}
                    {(this.state.procedures && this.state.procedures.length > 1) &&
                        <Col xs="12" md="4" lg="4" xl="4">
                            <ProcedureSelect className="m-0 p-0 mb-3" label="studentadministration.label.procedures" name="procedures" withEmpty procedures={this.state.procedures ? this.state.procedures : []} procedureId={this.props.procedureId} handleProcedures={this.props.dispatchProcedure} />
                        </Col>
                    }
                </Row>

                { /* Table */}
                <Container fluid className="m-0 p-0 border">
                    <Table striped bordered className="m-0 p-0 table-fixed w-100">
                        <TableHead data={this.getTableHead()} order={this.order} />
                        <TableBody
                            loading={this.props.loading}
                            className={"w-100"}
                            data={this.props.users}
                            show={this.getTableShow()}
                            edit={this.handleEdit.bind(this)}
                            delete={this.handleDelete.bind(this)}
                            resetPwdUserEmail={CheckAuthorization("useradministration-reset-pwd-teacher", this.props.auth.roles) ? this.handleConfirmPwdResetEMail : null}
                            resetPwdUserPdf={CheckAuthorization("useradministration-reset-pwd-teacher", this.props.auth.roles) ? this.handleConfirmPwdResetTeacherPdf : null}
                            reset={this.handleReset.bind(this)}
                        />
                    </Table>
                </Container>

                { /* Pages */}
                <Container fluid className="d-flex justify-content-end mt-3 p-0">
                    <Pages pages={parseInt(this.state.pages, 10)} totalpages={parseInt(this.props.totalPages, 10)} page={parseInt(this.state.page, 10)} changePage={this.handlePage} />
                </Container>

                { /* MODAL for new and edit */}
                <UserAdministrationModal open={this.state.openModalNewEdit} toggle={this.handleOpenModalNewEdit} user={this.state.editData} save={this.save.bind(this)} procedures={this.state.procedures} />

                { /* MODAL for new and edit */}
                <ImportUserModal school={this.props.school} open={this.state.openModalImport} toggle={this.handleOpenModalImport} importFileData={this.state.importFileData} refreshData={() => this.getData.bind(this)} />

                { /* MODAL for new upload error */}
                <Modal isOpen={this.state.showImportUploadError} toggle={this.handleOpenModalUploadError} size="lg" className="modal-height-100 modal-width-100" aria-live="polite" aria-atomic="true" aria-hidden={!this.handleOpenModalUploadError} >
                    <ModalHeader><FormattedMessage id="uploaderrormodal.title" /></ModalHeader>

                    <ModalBody>
                        {
                            this.state.importUploadError &&
                            <Row><Col>{this.state.importUploadError}</Col></Row>
                        }
                        {    /*this.state.importUploadError && typeof this.state.importUploadError === 'object' && this.state.importUploadError.errors.map((el) => {
                                return <Row><Col>{el}</Col></Row>
                            })*/
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" onClick={this.handleCloseUploadErrorModal.bind(this)}><FormattedMessage id="button.close" /></Button>
                    </ModalFooter>
                </Modal>

            </Container>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        schoolId: state.filter.school && state.filter.school.id ? state.filter.school.id : -1,
        school: state.filter.school && state.filter.school ? state.filter.school : {},
        schools: state.schools.schoolsForUser ? state.schools.schoolsForUser : [],
        totalPages: state.userProfile.total_pages,
        total: state.userProfile.total,
        roles: state.userProfile.roles,
        roleId: state.filter.role && state.filter.role.id ? state.filter.role.id : -1,
        certified: state.filter.certified,
        users: state.userProfile.users,
        loading: state.userProfile.loading,
        departments: state.departments.departments,
        departmentId: state.filter.department && state.filter.department.id ? state.filter.department.id : -1,
        schooltypes: state.schools.schooltypes,
        schooltypeId: state.filter.schooltype && state.filter.schooltype.id ? state.filter.schooltype.id : -1,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        deleteUserState: state.userProfile.deleteUserState,
        createUserState: state.userProfile.createUserState,
        updateUserState: state.userProfile.updateUserState,
        createError: state.userProfile.createError,
        resetPwdState: state.userProfile.resetPwdState,
        resetPwdSuccessfulResetted: state.userProfile.resetPwdSuccessfulResetted,
        resetPwdTotalUsers: state.userProfile.resetPwdTotalUsers,
        resetPwdErrors: state.userProfile.resetPwdErrors,
        resetPwdUserPdfState: state.userProfile.resetPwdUserPdfState,
        resetPwdUserPdfUserdata: state.userProfile.resetPwdUserPdfUserdata,
        resetPwdUserPdfKompoUrl: state.userProfile.resetPwdUserPdfKompoUrl,
        resetPwdUserPdfError: state.userProfile.resetPwdUserPdfError,
        procedures: state.procedures ? state.procedures : [],
        procedureId: state.filter.procedure /*&& state.filter.procedure.id*/ ? state.filter.procedure.id : -1,
        sendResetPwdMailError: state.auth.sendResetPwdMailError,
        sendResetPwdMailSuccess: state.auth.sendResetPwdMailSuccess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetSchoolsForUser: (token) => {
            dispatch(schoolsActions.getSchoolsForUser(token, "name", false, null, null, null, null, null, null, true));
        },
        dispatchSchool: (school) => {
            dispatch(filterActions.school(school));
        },
        dispatchGetRoles: (token) => {
            dispatch(userProfileActions.getRoles(token));
        },
        dispatchRole: (role) => {
            dispatch(filterActions.role(role));
        },
        dispatchCertified: (certified) => {
            dispatch(filterActions.certified(certified));
        },
        dispatchGetUsers: (token, order, desc, search, page, pagelimit, filter) => {
            dispatch(userProfileActions.getUsers(token, order, desc, search, page, pagelimit, filter));
        },
        dispatchGetDepartments: (token) => {
            dispatch(departmentsActions.getDepartments(token, "name", false, null, null, null));
        },
        dispatchGetSchooltypes: (token) => {
            dispatch(schoolsActions.getSchooltypes(token, "name", false));
        },
        dispatchDepartment: (department) => {
            dispatch(filterActions.department(department));
        },
        dispatchSchooltype: (schooltype) => {
            dispatch(filterActions.schooltype(schooltype));
        },
        dispatchDeleteUser: (token, userId) => {
            dispatch(userProfileActions.deleteUser(token, userId));
        },
        dispatchCreateUser: (token, user) => {
            dispatch(userProfileActions.createUser(token, user));
        },
        dispatchUpdateUser: (token, user) => {
            dispatch(userProfileActions.updateUser(token, user));
        },
        dispatchResetPwd: (token, filter) => {
            dispatch(userProfileActions.resetPwd(token, filter));
        },
        dispatchResetPwdTeacherPdf: (token, teacherId) => {
            dispatch(userProfileActions.resetPwdUserPdf(token, teacherId));
        },
        dispatchSendResetPwdMail: (login) => {
            dispatch(loginActions.sendResetPasswordMail(login));
        },
        dispatchResetUser: (token, userId) => {
            dispatch(userProfileActions.resetUser(token, userId));
        },
        dispatchProcedure: (procedure) => {
            dispatch(filterActions.procedure(procedure));
        }
    };
};


export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAdministrationPage));