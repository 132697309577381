import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Label, Alert, Button, Row, Col, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import ExecutionSelect from '../components/ExecutionSelect';
import translationsVars from '../translations/translationsVars';
import { verbexerciseActions, kompoGResultActions } from '../actions';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import config from '../config/config';
import GeneratePDF from '../components/GeneratePDF';
import { generateVerbExercisePDF } from '../pdf/VerbExercisePDF';
import Loading from '../components/Loading';

class VerbExercisePage extends Component {

    static propTypes = {
        dispatchGetVerbs: PropTypes.func.isRequired,
        dispatchSaveVerbs: PropTypes.func.isRequired,
        dispatchSaveResultVerbs: PropTypes.func.isRequired,
        executionId: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            studentsId: -1,
            confirm: false,
            alert: { visible: false, textId: "alert.save", color: "success" },
            translationsVars: translationsVars,
            firstLoad: true,
            closeStartDescription: false,
            closeVerbEvaluation: false,
            closeVerbAnswers: false,
            circlesLocked: false,
            saved: false,
            verbIndex: 0,
            verbs: [],
            verbsLength: 0,
            verbsAnswers: [],
            verbsPrivate: [],
            verbsBoth: [],
            verbsWork: [],
            initialState: true,
            changeIdx: 0,
            userDataExistsVerbEvaluation: false,
            userDataExistsVerbAnswers: false,
            userDataExistsCircles: false,
            userDataExistsHighlight: false,
            userDataExists: false
        };
    }

    componentDidMount() {
        this.setState({ translationsVars: { ...this.state.translationsVars, filename: this.props.auth.firstname + "__" + this.props.auth.lastname } });

        document.addEventListener('keydown', this._handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    goToNextPage() {
        if (this.state.nextPage) {
            //remove the confirm dialog
            this.props.history.block((location, action) => {
                return true;
            });
            //push the new page
            this.props.history.push(this.state.nextPage);
        }
    }

    setCirclesWhenUserExists = (verbsArg) => {
        let verbsWork = this.state.verbsWork;
        let verbsBoth = this.state.verbsBoth;
        let verbsPrivate = this.state.verbsPrivate;
        let verbsAnswers = [];
        let verbs = (verbsArg !== null) ? verbsArg : this.state.verbs;
        const userAnswers = verbs.filter(el => el.params !== null);
        userAnswers.forEach(el => {
            if (el.params.interestarea === 'verbsWork') {
                if (verbsWork.filter(vw => vw.id === el.id).length === 0) {
                    verbsWork.push(el);
                }
            } else if (el.params.interestarea === 'verbsBoth') {
                if (verbsBoth.filter(vb => vb.id === el.id).length === 0) {
                    verbsBoth.push(el);
                }
            } else if (el.params.interestarea === 'verbsPrivate') {
                if (verbsPrivate.filter(vp => vp.id === el.id).length === 0) {
                    verbsPrivate.push(el);
                }
            } else {
                verbsAnswers.push(el)
            }

        });
        this.setState({ verbsWork: verbsWork, verbsBoth: verbsBoth, verbsPrivate: verbsPrivate, verbsAnswers: verbsAnswers });
    }

    handleGoToEvaluation = (e, verbsArg = null) => {
        this.setCirclesWhenUserExists(verbsArg);
        this.setState({ closeStartDescription: true, closeVerbEvaluation: true, closeVerbAnswers: true, circlesLocked: true, saved: true });
    }

    handleContinue = () => {

        if (this.state.userDataExistsVerbEvaluation && this.state.userDataExistsVerbAnswers && this.state.userDataExistsCircles) {
            this.setCirclesWhenUserExists(null);
            this.setState({ closeStartDescription: true, closeVerbEvaluation: true, closeVerbAnswers: true, circlesLocked: true });
        }

        if (!this.state.userDataExistsCircles) {
            if (this.state.userDataExistsVerbEvaluation && this.state.userDataExistsVerbAnswers) {
                this.setCirclesWhenUserExists(null);
                this.setState({ closeStartDescription: true, closeVerbEvaluation: true, closeVerbAnswers: true });
            }

            if (this.state.userDataExistsVerbEvaluation && !this.state.userDataExistsVerbAnswers) {
                this.filterVerbs(this.state.verbs);
                this.setState({ closeStartDescription: true, closeVerbEvaluation: true });
            }
        }
    }

    componentDidUpdate(prevProps) {

        if (this.props.executionId !== -1 && (this.state.firstLoad || prevProps.executionId !== this.props.executionId)) {
            this.setState({ firstLoad: false });

            this.props.dispatchGetVerbs(this.props.auth.token, this.props.executionId);
            this.props.dispatchGetResult(this.props.auth.token, this.props.executionId);
        }

        if (prevProps.verbs !== this.props.verbs) {
            let verbs = [...this.props.verbs];
            this.setState({ verbs: verbs, verbsLength: verbs.length });
            if (this.props.result && this.props.result.releaseTeacher === 1) {
                //no time delay
                const answers = verbs.filter(el => el.params !== null);
                this.handleGoToEvaluation(null, answers)
            }

            const userAnswers = verbs.filter(el => el.params !== null);
            let userDataExistsVerbEvaluation = false;
            let userDataExistsVerbAnswers = false;
            let userDataExistsCircles = false;
            let userDataExistsHighlight = false;

            if (userAnswers.length > 0) {
                userDataExistsVerbEvaluation = true;
                const userAnswersStep2 = userAnswers.filter(el => el.params.answer && el.params.answer !== "");
                //check if all answers given
                if (userAnswersStep2.length === userAnswers.length) {
                    userDataExistsVerbAnswers = true;
                    const userAnswersStep3 = userAnswers.filter(el => el.params.interestarea && el.params.interestarea !== "");
                    if (userAnswersStep3.length === userAnswers.length) {
                        userDataExistsCircles = true;
                        const userAnswersStep4 = userAnswers.filter(el => el.params.highlight && el.params.highlight === 1);
                        const userAnswersStep4_assign = userAnswers.filter(el => el.params.interestarea === "verbsBoth" || el.params.interestarea === "verbsWork");
                        if (userAnswersStep4_assign.length <= 3 || userAnswersStep4.length === 3) {
                            userDataExistsHighlight = true;
                            this.handleGoToEvaluation(null, verbs);
                        }
                    }
                }
                let userDataExists = userDataExistsVerbEvaluation || userDataExistsVerbAnswers || userDataExistsCircles || userDataExistsHighlight;
                this.setState({
                    userDataExists: userDataExists, userDataExistsVerbEvaluation: userDataExistsVerbEvaluation, userDataExistsVerbAnswers: userDataExistsVerbAnswers,
                    userDataExistsCircles: userDataExistsCircles, userDataExistsHighlight: userDataExistsHighlight
                });
            }
        }

        if (prevProps.saveStatus !== this.props.saveStatus) {
            if (this.props.saveStatus) {
                this.setState({ alert: { visible: true, textId: "alert.save", color: "success" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            } else if (this.props.saveStatus !== null) {
                this.setState({ alert: { visible: true, textId: "alert.save-error", color: "danger" } });
                setTimeout(this.handleHideAlert, config.alertTimeOut);
            }
        }

        if (prevProps.result !== this.props.result && ((this.props.result.interests && this.props.result.interests.length === 3) || this.props.result.releaseTeacher === 1)) {
            this.handleGoToEvaluation();
        }
    }

    handleHideAlert = (e) => {
        this.setState({ alert: { ...this.state.alert, visible: false } });
    };

    toggleConfirm = (e) => {
        this.setState({
            confirm: !this.state.confirm
        });
    };


    _handleKeyDown = (event) => {
        var JKEY = 74;
        var YKEY = 89;
        var NKEY = 78;
        var MINUSKEY = 173;
        var PLUSKEY = 187;
        var SUBSTRACTKEY = 189;
        var PLUSNUMLOCKKEY = 171;
        var SUBSTRACTNUMLOCKKEY = 109;
        var ADDKEY = 107;
        var ARROWLEFTEY = 37;
        var ARROWRIGHTKEY = 39;
        var ONEKEY = 49;
        var TWOKEY = 50;
        var ONENUMLOCKKEY = 97;
        var TWONUMLOCKKEY = 98;
        if (this.state.closeStartDescription && !this.state.closeVerbEvaluation) {
            switch (event.keyCode) {
                case JKEY:
                case YKEY:
                case PLUSKEY:
                case PLUSNUMLOCKKEY:
                case ADDKEY:
                case ARROWRIGHTKEY:
                case TWOKEY:
                case TWONUMLOCKKEY:
                    this.handleGoodInput();
                    return;
                case NKEY:
                case MINUSKEY:
                case SUBSTRACTKEY:
                case SUBSTRACTNUMLOCKKEY:
                case ARROWLEFTEY:
                case ONEKEY:
                case ONENUMLOCKKEY:
                    this.handleBadInput();
                    return;
                default:
                    break;
            }
        }

    }

    /**
     * restart of evaluation; set verbs to initial state
     */
    handleCloseDescriptionPage = () => {
        if (this.state.userDataExists) {
            this.props.dispatchDeleteVerbs(this.props.auth.token, this.props.executionId);
            let verbs = this.state.verbs;
            verbs.forEach(v => {
                v.params = null;
            })
            this.setState({ verbs: verbs });
        }
        this.setState({ closeStartDescription: true })
    };

    handleGoodInput = () => {
        var copiedVerbs = JSON.parse(JSON.stringify(this.state.verbs));
        copiedVerbs[this.state.verbIndex].params = { answer: '', interestarea: '', highlight: 0 };
        this.setState({ verbs: copiedVerbs });
        if ((this.state.verbsLength - 1) > this.state.verbIndex) {
            this.setState({ verbIndex: this.state.verbIndex + 1 });
        } else {
            // this.setState({closeVerbEvaluation: true});
            this.filterVerbs(copiedVerbs);
        }
    }

    handleBadInput = () => {
        if ((this.state.verbsLength - 1) > this.state.verbIndex) {
            this.setState({ verbIndex: this.state.verbIndex + 1 });
        } else {
            //this.setState({closeVerbEvaluation: true});
            this.filterVerbs(this.state.verbs);
        }
    }

    filterVerbs(verbs) {
        var array = [];
        var verbsArray = JSON.parse(JSON.stringify(verbs));
        verbsArray.forEach(el => {
            if (el.params) {
                array.push(el);
            }
        });
        this.setState({ verbsAnswers: array, closeVerbEvaluation: true });
        //cache verbs 
        this.props.dispatchSaveVerbs(this.props.auth.token, this.props.executionId, array);

    }

    handleAnswer = (e, id) => {
        var verbsArray = JSON.parse(JSON.stringify(this.state.verbs));
        var verbsAnswersArray = JSON.parse(JSON.stringify(this.state.verbsAnswers));

        verbsArray.forEach(el => {
            if (el.id === id) {
                el.params.answer = e;
            }
        });

        verbsAnswersArray.forEach(el => {
            if (el.id === id) {
                el.params.answer = e;
            }
        });
        this.setState({ verbs: verbsArray });
        this.setState({ verbsAnswers: verbsAnswersArray });

        if (this.state.changeIdx !== 100) {
            this.setState({ changeIdx: this.state.changeIdx + 1 });
        } else {
            this.setState({ changeIdx: 0 });
            this.props.dispatchSaveVerbs(this.props.auth.token, this.props.executionId, this.state.verbs)
        }
    };

    checkIfallQuestionsAnswered = () => {
        var disabled = false;
        // if (this.state.verbsAnswers.length >= 0) {
        this.state.verbs.forEach(el => {
            if (el.params && el.params.answer === '') {
                disabled = true;
                return;
            }
        });
        // }        
        return disabled;
    };

    handleCloseVerbAnswers = () => {
        if (this.state.verbsAnswers.length > 0) {
            this.setState({ closeVerbAnswers: true });
            this.props.dispatchSaveVerbs(this.props.auth.token, this.props.executionId, this.state.verbs)
        } else {
            this.setState({ closeVerbAnswers: true, closeStartDescription: true, closeVerbEvaluation: true, circlesLocked: true, saved: true });
            this.props.dispatchSaveResultVerbs(this.props.auth.token, this.props.executionId, ["", "", ""]);
            this.props.dispatchSaveResult(this.props.auth.token, this.props.executionId, []);
        }
    };

    handleRestartVerbAnswers = () => {
        this.setState({ verbIndex: 0 });
        this.setState({ closeVerbEvaluation: false });
        this.setState({ verbsAnswers: [] });
    };

    onDragEnd = (result) => {
        const { source, destination } = result;

        if (!destination || destination.droppableId === 'verbsAnswers' || source.droppableId === destination.droppableId) {
            return;
        }

        const response = this.move(this.state[source.droppableId], this.state[destination.droppableId], source, destination);
        const newState = { ...this.state };
        newState[source.droppableId] = response[source.droppableId];
        newState[destination.droppableId] = response[destination.droppableId];
        this.setState(newState);
    };

    getItemStyle = (isDragging) => {
        return isDragging ? 'drag-item-dragged' : 'drag-item';
    };
    getListStyle = isDraggingOver => {
        return isDraggingOver ? 'drag-list-draggingover' : '';
    };

    saveInterestArea(verbId, interestarea) {
        // var copiedVerbs = JSON.parse(JSON.stringify(this.state.verbs));
        var copiedVerbs = this.state.verbs;
        copiedVerbs.forEach(el => {
            if (el.id === verbId) {
                el.params.interestarea = interestarea;
            }
        });
        this.setState({ verbs: copiedVerbs });
    }

    move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        this.saveInterestArea(removed.id, droppableDestination.droppableId);
        removed.params.interestarea = droppableDestination.droppableId;

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    handlePDF = () => {
        generateVerbExercisePDF(this.props, this.state);
    };

    handleLockVerbCircles = () => {
        let sum = this.state.verbsBoth.length + this.state.verbsWork.length;
        if (sum <= 3) {
            var arr = [];
            var arrBoth = [];
            var arrWork = [];
            this.state.verbsBoth.forEach(e => {
                arr.push(e.id);
                arrBoth.push(e.id);
            })
            this.state.verbsWork.forEach(e => {
                arr.push(e.id);
                arrWork.push(e.id);
            })
            var copiedVerbs = this.setVerbHighlights(arr, true);
            this.setState({ verbs: copiedVerbs });

            var copiedVerbsInterestBoth = this.setInterestareaHighlights(arrBoth, "verbsBoth", true);
            var copiedVerbsInterestWork = this.setInterestareaHighlights(arrWork, "verbsWork", true);

            this.setState({ circlesLocked: true, verbsBoth: copiedVerbsInterestBoth, verbsWork: copiedVerbsInterestWork });

            this.handleSave(copiedVerbs);
        } else {
            this.props.dispatchSaveVerbs(this.props.auth.token, this.props.executionId, this.state.verbs)
            this.setState({ circlesLocked: true });
        }
    };

    checkVerbHighlights = () => {
        var copiedVerbs = JSON.parse(JSON.stringify(this.state.verbs));
        var counter = 0;
        copiedVerbs.forEach(el => {
            if (el.params && el.params.highlight > 0) {
                counter++;
            }
        });
        if (counter === 3) {
            return true;
        }
        return false;
    }

    setVerbHighlights = (verbIds, highlight) => {
        var copiedVerbs = JSON.parse(JSON.stringify(this.state.verbs));
        copiedVerbs.forEach((el, index) => {
            if (verbIds.includes(el.id)) {
                el.params.highlight = highlight ? 1 : 0;
                copiedVerbs[index].params.highlight = highlight ? 1 : 0;
            }
        });
        return copiedVerbs;
    }

    setInterestareaHighlights = (verbIds, interestarea, highlight) => {
        var copiedVerbs = JSON.parse(JSON.stringify(this.state[interestarea]));
        copiedVerbs.forEach((el, index) => {
            if (verbIds.includes(el.id)) {
                el.params.highlight = highlight ? 1 : 0;
                copiedVerbs[index].params.highlight = highlight ? 1 : 0;
            }
        });
        return copiedVerbs;
    }

    handleSelectHighlight = (verb, interestarea) => {
        var copiedVerbs, copiedVerbsInterest;
        if (verb.params && verb.params.highlight > 0) {
            copiedVerbs = this.setVerbHighlights([verb.id], false);
            copiedVerbsInterest = this.setInterestareaHighlights([verb.id], interestarea, false);
            if (interestarea === "verbsBoth") {
                this.setState({ verbs: copiedVerbs, verbsBoth: copiedVerbsInterest });
            } else {
                this.setState({ verbs: copiedVerbs, verbsWork: copiedVerbsInterest });
            }

        }
        else if (!this.checkVerbHighlights()) {
            copiedVerbs = this.setVerbHighlights([verb.id], true);
            copiedVerbsInterest = this.setInterestareaHighlights([verb.id], interestarea, true);
            if (interestarea === "verbsBoth") {
                this.setState({ verbs: copiedVerbs, verbsBoth: copiedVerbsInterest });
            } else {
                this.setState({ verbs: copiedVerbs, verbsWork: copiedVerbsInterest });
            }
        }
    };

    getHighlightStyle = (highlight) => {
        return highlight > 0 ? 'highlight' : '';
    };

    getNotSavedStyle = () => {
        return this.state.saved > 0 ? '' : ' notsaved ';
    };

    handleSave = (verbTemp = []) => {
        let verbs = this.state.verbs;
        if (verbTemp.length > 0) {
            verbs = verbTemp;
        }
        this.setState({ saved: true });
        this.props.dispatchSaveVerbs(this.props.auth.token, this.props.executionId, verbs);

        let verbsResult = [];
        verbs.forEach((verb) => {
            if (verb.params && verb.params.highlight === 1) {
                verbsResult.push(verb.name);
            }
        })
        this.props.dispatchSaveResultVerbs(this.props.auth.token, this.props.executionId, verbsResult);
        this.props.dispatchSaveResult(this.props.auth.token, this.props.executionId, []);
    }

    showTooltip = (index, type) => {
        let text = "";
        let verb;
        if (type === "verbsBoth" && index < this.state.verbsBoth.length) {
            verb = this.state.verbsBoth[index];
        } else if (type === "verbsWork" && index < this.state.verbsWork.length) {
            verb = this.state.verbsWork[index];
        } else if (type === "verbsPrivate" && index < this.state.verbsPrivate.length) {
            verb = this.state.verbsPrivate[index];
        }
        if (verb !== null) {
            text += this.props.intl.formatMessage({ id: verb.question }) + " \n ";
            text += verb.params.answer;
        }
        return text;
    }

    render() {
        if (this.props.loading) {
            return (
                <Container fluid role="main" className="content-margin">
                    <Container fluid className="p-0 fw-bold">
                        <h1><FormattedMessage id="verb-exercise.label.verb-exercise" /></h1>
                        <Loading />
                    </Container>
                </Container>
            );

        }
        else if (this.props.executionId === -1) {
            return (
                <Container fluid role="main" className="content-margin">
                    <Container fluid className="p-0 fw-bold">
                        <h1><FormattedMessage id="verb-exercise.label.verb-exercise" /></h1>

                        {/* Select Execution */}
                        <Row>
                            <Col xs="12" sm="6" md="6" lg="6" xl="3">
                                <ExecutionSelect className="mb-3 p-0" executionId={this.props.executions_id} past={true} />
                            </Col>
                        </Row>
                        <FormattedMessage id="verb-exercise.label.noavailable" />
                    </Container>
                </Container>
            );
        } else {
            return (
                <Container fluid role="main" className="content-margin">
                    {/* Alert */}
                    <Alert isOpen={this.state.alert.visible} toggle={this.handleHideAlert} color={this.state.alert.color} className="fixed-top text-center"><FormattedMessage id={this.state.alert.textId} /></Alert>

                    <h1><FormattedMessage id="verb-exercise.label.verb-exercise" /></h1>

                    {!this.state.closeStartDescription &&
                        <Container fluid className="start-description-container">
                            <FormattedMessage id="verb-exercise.start-description" values={{ br: <br /> }} />
                            <div className="next-button-container">
                                {!this.state.userDataExists &&
                                    <Button className="verbexercise-next-button" color="primary" onClick={this.handleCloseDescriptionPage}><FormattedMessage id={"verb-exercise.button.next"} /></Button>
                                }
                                {
                                    this.state.userDataExists &&
                                    <span>
                                        <Button disabled={this.props.result && this.props.result.releaseTeacher === 1 ? true : false} className="verbexercise-next-button" color="primary" onClick={this.handleCloseDescriptionPage}><FormattedMessage id={"verb-exercise.button.restart"} /></Button>
                                        <Button color="primary" className="ms-3 verbexercise-next-button" onClick={this.handleContinue} ><FormattedMessage id="verb-exercise.button.continue" /></Button>
                                    </span>
                                }
                            </div>
                        </Container>
                    }
                    {this.state.closeStartDescription && !this.state.closeVerbEvaluation &&
                        <Container fluid className="p-3">
                            <FormattedMessage id="verb-exercise.first-task" values={{ br: <br /> }} />
                            <Container fluid className="verb-container p-3">
                                <Container fluid className="verb-card-container px-3">
                                    <Container fluid className="verb-info-container">
                                        <Container fluid className="verb-index-length-container">
                                            <Label className="verb-info-label">{this.state.verbIndex + 1 + '/' + this.state.verbsLength}</Label>
                                        </Container>
                                    </Container>
                                    <h2 className="verb-name-label">{this.state.verbs && this.state.verbs.length > 0 ? <FormattedMessage id={this.state.verbs[this.state.verbIndex].name} /> : ""} </h2>
                                    <Container fluid className="evaluation-btn-box">
                                        <Container className="verb-smiley-container" onClick={this.handleBadInput}>
                                            <img className="verb-smiley" src={require('../assets/images/Smiley_04.svg').default} alt={this.props.intl.formatMessage({ id: "verbs.smileyNeg" })} />
                                        </Container>
                                        <Container className="verb-smiley-container" onClick={this.handleGoodInput}>
                                            <img className="verb-smiley" src={require('../assets/images/Smiley_01.svg').default} alt={this.props.intl.formatMessage({ id: "verbs.smileyPos" })} />
                                        </Container>
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                    }
                    {this.state.closeStartDescription && this.state.closeVerbEvaluation && !this.state.closeVerbAnswers &&
                        <Container fluid>
                            {this.state.verbsAnswers.length > 0 &&
                                <div className="mb-2" ><FormattedMessage id="verb-exercise.second-task" values={{ br: <br /> }} /></div>
                            }
                            <Container fluid className="verb-answer-container">
                                {this.state.verbsAnswers.length > 0 && this.state.verbsAnswers.map((verb, index) => {
                                    return <Container fluid className="verb-answer-card-container" key={index}>
                                        <Container fluid className="verb-answer-name-container">
                                            <p className="verb-answer-label"><FormattedMessage id={verb.name} /></p>
                                            <p className="verb-answer-question-label"><FormattedMessage id={verb.question} /></p>
                                        </Container>
                                        <Container fluid className="verb-answer-input-container">
                                            <Input
                                                type="textarea"
                                                id={'answer' + verb.id}
                                                name={'answer' + verb.id}
                                                innerRef={'answer' + verb.id}
                                                ref={'answer' + verb.id}
                                                placeholder={this.props.intl.formatMessage({ id: "verb-textarea-placeholder" })}
                                                autoComplete="off"
                                                value={verb.params ? verb.params.answer : ''}
                                                style={{ height: '100%', width: '100%', border: 'none', backgroundColor: '#e9ecef', resize: "none" }}
                                                onChange={(e) => { this.handleAnswer(e.target.value, verb.id) }}
                                            />
                                        </Container>

                                    </Container>
                                })
                                }
                            </Container>
                            {this.state.verbsAnswers.length === 0 &&
                                <div className="no-verbs-label">
                                    <FormattedMessage id="no.verbs.selected" />
                                </div>
                            }
                            <div className="next-button-container">
                                {this.state.verbsAnswers.length === 0 ?
                                    <Button className="verbexercise-next-button me-2" color="primary" disabled={this.checkIfallQuestionsAnswered()} onClick={this.handleRestartVerbAnswers}>
                                        <FormattedMessage id={"verbs.btn.restart"} />
                                    </Button>
                                    : null
                                }
                                <Button className="verbexercise-next-button" color="primary" disabled={this.checkIfallQuestionsAnswered()} onClick={this.handleCloseVerbAnswers}>
                                    <FormattedMessage id={"button.next"} />
                                </Button>
                            </div>
                        </Container>
                    }
                    {this.state.closeStartDescription && this.state.closeVerbEvaluation && this.state.closeVerbAnswers && !this.state.circlesLocked &&
                        <Container fluid>
                            <div className="mb-2" ><FormattedMessage id="verb-exercise.third-task" values={{ br: <br /> }} /></div>
                            <div className="verb-interestarea-wrapper">
                                <Container fluid className="verb-interestarea-container">
                                    <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Row className="circle-box">
                                            <Col className="private-drop-box">
                                                <Droppable droppableId='verbsPrivate'>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            className={"drag-list " + this.getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {this.state.verbsPrivate && this.state.verbsPrivate.map((verb, index) => (
                                                                <Draggable key={verb.id} draggableId={verb.id + '_private'} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={this.getItemStyle(snapshot.isDragging)}
                                                                            title={this.showTooltip(index, "verbsPrivate")}
                                                                        >
                                                                            <FormattedMessage id={verb.name} />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </Col>
                                            <Col className="both-drop-box">
                                                <Droppable droppableId='verbsBoth'>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            className={"drag-list " + this.getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {this.state.verbsBoth && this.state.verbsBoth.map((verb, index) => (
                                                                <Draggable key={verb.id} draggableId={verb.id + '_both'} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={this.getItemStyle(snapshot.isDragging)}
                                                                            title={this.showTooltip(index, "verbsBoth")}
                                                                        >
                                                                            <FormattedMessage id={verb.name} />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </Col>
                                            <Col className="work-drop-box">
                                                <Droppable droppableId='verbsWork'>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            className={"drag-list " + this.getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {this.state.verbsWork && this.state.verbsWork.map((verb, index) => (
                                                                <Draggable key={verb.id} draggableId={verb.id + '_work'} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={this.getItemStyle(snapshot.isDragging)}
                                                                            title={this.showTooltip(index, "verbsWork")}
                                                                        >
                                                                            <FormattedMessage id={verb.name} />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </Col>
                                        </Row>

                                        <div className="verb-list-container">
                                            {this.state.verbsAnswers.length > 0 &&
                                                <Droppable droppableId='verbsAnswers'>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.droppableProps}
                                                            className="drag-list"
                                                        >
                                                            {this.state.verbsAnswers.map((verb, index) => (
                                                                <Draggable key={verb.id} draggableId={verb.id + '_answer'} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={this.getItemStyle(snapshot.isDragging)}
                                                                            title={this.props.intl.formatMessage({ id: verb.question }) + " \n " + verb.params.answer}
                                                                        >
                                                                            <FormattedMessage id={verb.name} />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            }{this.state.verbsAnswers.length === 0 &&
                                                <FormattedMessage id="verbs.all-assigned" />
                                            }
                                        </div>
                                    </DragDropContext>
                                </Container>
                                <div className="next-button-container">
                                    <Button className="verbexercise-circles-next-button" color="primary" disabled={this.state.verbsAnswers.length > 0} onClick={this.handleLockVerbCircles}>
                                        <FormattedMessage id="button.next" />
                                    </Button>
                                </div>
                            </div>
                        </Container>
                    }
                    {this.state.closeStartDescription && this.state.closeVerbEvaluation && this.state.closeVerbAnswers && this.state.circlesLocked &&
                        <Container fluid>
                            {!this.state.saved ?
                                <div className="mb-2"><FormattedMessage id="verb-exercise.forth-task" values={{ br: <br /> }} /> </div>
                                : <Container fluid className="sticky2 bg-white m-0">
                                    <Row className="p-0 mb-4 m-0">
                                        <GeneratePDF className="p-0 m-0 d-flex justify-content-end flex-row" handlePDF={this.handlePDF.bind()} />
                                    </Row>
                                </Container>
                            }
                            <div className="verb-interestarea-wrapper">
                                <Container fluid className="verb-interestarea-container circles-closed">
                                    <div className="circle-box">
                                        <div className="private-drop-box">
                                            <div className="drag-list">
                                                {this.state.verbsPrivate && this.state.verbsPrivate.map((verb, index) => (
                                                    <div className="drag-item-private" key={index} title={this.showTooltip(index, "verbsPrivate")}>
                                                        <FormattedMessage id={verb.name} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="both-drop-box">
                                            <div className="drag-list">
                                                {this.state.verbsBoth && this.state.verbsBoth.map((verb, index) => (
                                                    <div
                                                        key={index}
                                                        className={"drag-item " + this.getHighlightStyle(verb.params.highlight) + this.getNotSavedStyle()}
                                                        onClick={!this.state.saved ? this.handleSelectHighlight.bind(this, verb, 'verbsBoth') : null}
                                                        title={this.showTooltip(index, "verbsBoth")}
                                                    >
                                                        <FormattedMessage id={verb.name} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="work-drop-box">
                                            <div className="drag-list">
                                                {this.state.verbsWork && this.state.verbsWork.map((verb, index) => (
                                                    <div
                                                        key={index}
                                                        className={"drag-item " + this.getHighlightStyle(verb.params.highlight) + this.getNotSavedStyle()}
                                                        onClick={!this.state.saved ? this.handleSelectHighlight.bind(this, verb, 'verbsWork') : null}
                                                        title={this.showTooltip(index, "verbsWork")}
                                                    >
                                                        <FormattedMessage id={verb.name} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* {!this.state.saved ?                
                                        <div className="verb-list-container">
                                            <FormattedMessage id="verbs.all-assigned" />
                                        </div>
                                        : null
                                    } */}
                                </Container>
                                {!this.state.saved ?
                                    <div className="next-button-container">
                                        <Button className="verbexercise-circles-next-button" color="primary" disabled={!this.checkVerbHighlights()} onClick={this.handleSave}>
                                            <FormattedMessage id="button.save" />
                                        </Button>
                                    </div>
                                    : null
                                }
                            </div>
                        </Container>
                    }
                </Container>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        executionId: state.filter.execution ? state.filter.execution.id : -1,
        verbs: state.verbexercise.verbs,
        saveStatus: state.verbexercise.saveStatus,
        result: state.kompoGResult.result,
        loading: state.kompoGResult.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatchGetVerbs: (token, executionId) => {
            dispatch(verbexerciseActions.getVerbs(token, executionId, null, null));
        },
        dispatchSaveVerbs: (token, executionId, verbs) => {
            dispatch(verbexerciseActions.saveVerbs(token, executionId, verbs));
        },
        dispatchSaveResultVerbs: (token, executionId, verbsResult) => {
            dispatch(verbexerciseActions.saveResultVerbs(token, executionId, verbsResult));
        },
        dispatchSaveResult: (token, executionId, result) => {
            dispatch(kompoGResultActions.saveResult(token, executionId, result));
        },
        dispatchGetResult: (token, executionId) => {
            dispatch(kompoGResultActions.getResult(token, executionId, null, null));
        },
        dispatchDeleteVerbs: (token, executionId) => {
            dispatch(verbexerciseActions.deleteVerbs(token, executionId, null, null));
        },
    };
};

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(VerbExercisePage));

