import moment from 'moment';
import print from '../assets/print';

const config = {
    version: '1.2.0',

    defaultLanguage: 'de',
    alertTimeOut: 3000,
    dateFormat: { format: [moment.ISO_8601], month: '2-digit', year: '2-digit', day: '2-digit', weekday: 'short', hour: '2-digit', minute: '2-digit' },
    toolTipDelay: { show: 500, hide: 0 },
    icons: {
        print: { student: print.student, observer: print.observer },
        student: 'images/Schueler_neu.svg',
        observer: 'images/Beobachter.svg'
    } // don't forget to change stundent and observer in _feedback.scss
};

export default config;